import { useMemo } from "react";

const useFilteredAndSortedProducts = (products = [], filters) => {
    const { sort, brand, category, priceRange } = filters;

    const filteredProducts = useMemo(() => {
        if (!Array.isArray(products)) {
            return []; // Return an empty array if products is not an array
        }

        return products.filter((product) => {
            // Filter by category
            if (category !== "all" && product.category_name !== category) {
                return false;
            }

            // Convert filters.brand array to strings for comparison
            const brandFilters = brand.map(id => id.toString());

            // Filter by brand
            if (brandFilters.length > 0 && !brandFilters.includes(product.brand)) {
                return false;
            }

            // Filter by price range
            const price = parseFloat(product.saleprice);
            if (priceRange.min !== 0 || priceRange.max !== Infinity) {
                if (price < priceRange.min || price > priceRange.max) {
                    return false;
                }
            }

            return true;
        });
    }, [products, category, brand, priceRange]);

    const sortedProducts = useMemo(() => {
        if (!Array.isArray(filteredProducts)) {
            return []; // Return an empty array if filteredProducts is not an array
        }

        return filteredProducts.sort((a, b) => {
            const salePriceA = parseFloat(a.saleprice);
            const salePriceB = parseFloat(b.saleprice);

            switch (sort) {
                case "priceLowToHigh":
                    return salePriceA - salePriceB;
                case "priceHighToLow":
                    return salePriceB - salePriceA;
                default:
                    return 0; // No sorting
            }
        });
    }, [filteredProducts, sort]);

    return sortedProducts;
};

export default useFilteredAndSortedProducts;
