import React from 'react';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import EateLogo from '../../assets/mesenger-logo.jpg';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';

const LiveChatIcon = styled(Paper)(({ theme }) => ({
    width: { xs: '30px', sm: '44px' },
    height: { xs: '30px', sm: '44px' },
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    display: 'grid',
    placeItems: 'center',
    cursor: 'pointer',
    color: theme.palette.textcolor.main,
}));

const MessengerBox = styled(Box)(({ theme, open }) => ({
    backgroundColor: theme.palette.boxcolor.main,
    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.15)',
    minHeight: '239px',
    width: '300px',
    borderRadius: '18px',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'fixed',
    bottom: '60px',
    right: '20px',
    zIndex: 999,
    visibility: open ? 'visible' : 'hidden', 
    opacity: open ? 1 : 0,
    transition: 'visibility 0s, opacity 0.5s linear',
}));

const LiveChat = () => {
    const [open, setOpen] = React.useState(false);
    const [showExternalLinks, setShowExternalLinks] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
 
    const handleClose = () => {
        setOpen(false);
        setShowExternalLinks(false);
    };

    const handleClickOpenExternalLinks = () => {
        setShowExternalLinks(!showExternalLinks);
    };

    return (
        <>
            <MessengerBox open={open}  className={`messenger-box ${open ? 'open' : ''}`}>
                <Stack direction="row" alignItems="center">
                    <div className="ea-te-logo">
                        <img src={EateLogo} alt="logo" style={{ height: '36px', width: '36px' }} />
                    </div>
                    <Box className="three-dot" sx={{ marginLeft: 'auto', marginRight: '5px', position: 'relative' }}>
                        <MoreHorizIcon onClick={handleClickOpenExternalLinks} />
                        {showExternalLinks && (
                            <Box className="external-links">
                                <Link to="https://www.facebook.com/eatebd/" target="_blank" rel="noopener noreferrer">
                                    <Typography>
                                        Visit Our Fb Page
                                    </Typography>
                                </Link>
                            </Box>
                        )}
                    </Box>
                    <Box className="close-icon"  onClick={handleClose}>
                        <CloseIcon />
                    </Box>
                </Stack>
                <Box>
                    <Typography>Chat with ইয়ে-তে</Typography>
                    <Typography>হাই ! কিভাবে আপনাকে সাহায্য করতে পারি ?</Typography>
                </Box>
                <Box sx={{ marginTop: 'auto' }}>
                    <Button variant="contained" fullWidth color="primary">
                        Start Chat
                    </Button>
                </Box>
                <Box sx={{ textAlign: 'center', marginTop: '5px' }}>
                    <Typography>Powered by Messenger</Typography>
                </Box>
            </MessengerBox>

            <LiveChatIcon className="live-chat-icon" elevation={3} onClick={handleClickOpen} sx={{ width: { xs: '30px', sm: '44px' }, height: { xs: '30px', sm: '44px' } }}>
                <i className="fa-brands fa-facebook-messenger"></i>
            </LiveChatIcon>
        </>
    );
};

export default LiveChat;
