import { Typography } from '@mui/material';

//product description showing currectly 
export const ProductDescription = ({ description }) => {
    return (
        <div className="product-description">
            <Typography component="div" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
    );
};

// export const getImageUrl = (url) => {
//     // Check if the url is already a full URL
//     if (url.startsWith('http')) {
//         return url;
//     }
//     // Otherwise, prepend the base URL
//     return `http://localhost:8000${url}`;
// };


// Function to truncate the title
export const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
        return title.slice(0, maxLength) + '...';
    }
    return title;
};