import React , {useState , useEffect} from 'react';
import { Box, Container, Typography, Fab, Stack } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import shopLogo from '../../../assets/shop-logo.webp';
import shopBanner from '../../../assets/shop-banner.webp';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Footer from '../../../components/Footer/Footer'
import { getSingleVendorinfo } from '../../../components/ApiToFatch'
import SellerProductsPublic from './SellerProductsPublic';
import SellerProfileinfoPublic from './SellerProfileinfoPublic';
import { useParams } from 'react-router-dom';
import SeoInfo from '../../../components/seo/SeoInfo';

const SellerProfilePublic = () => {
  const { vid } = useParams();

  // for tabs
  const [value, setValue] = useState('1');
  const handleChange = (event, newValue) => {
      setValue(newValue);
  };

  const [storeinfo, setStoreinfo] = useState([]);

  useEffect(() => {
      const fetchData = async () => {
          try {
              const storeinfoData = await getSingleVendorinfo(vid);
              if (storeinfoData) {
                  setStoreinfo(storeinfoData);
              } else {
                  setStoreinfo([]);
              }
          } catch (error) {
              console.error('Error fetching data:', error);
              setStoreinfo([]);
          }
      };

      fetchData();
  }, [vid]);

  const logoImage = storeinfo.logo_image || shopLogo;
  const shopName = storeinfo.shop_name || 'Shop Name';


  return (
    <>
      <SeoInfo
        title={shopName}
        description='Seller on Ea-te.com'
        creatorname='Ea-te'
      />
      <Box className="seller-profile-container" sx={{display:'flex', flexDirection:'column', justifyContent: 'space-between', minHeight: '100vh'}}>
        <Container sx={{ padding: {xs: '12px 0 0 0' , md: '0px'} }}>
          <Box className="seller-profile-wrapper">
            <Box className="seller-profile-header" sx={{
              backgroundImage: `url(${shopBanner})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              padding: '20px 40px'
            }}>
              <Stack className="intro-box" sx={{ flexDirection: {xs: 'column' , sm: 'row'} , justifyContent: {xs:'center' , sm:'initial'}, alignItems: 'center', gap: {xs: '10px' , sm:'40px'}, backgroundColor: 'white', width: {xs:'auto' , sm: 'fit-content'}, padding: '20px 40px', borderRadius: '3px' }}>
                <Box className="intro-img" sx={{ width: '60px', height: '60px' }}>
                  <img src={logoImage} alt="shop-logo" style={{ width: '100%', height: '100%' }} />
                </Box>
                <Stack direction="column" spacing={1} className="intro-text">
                  <Typography variant='h5'>{shopName}</Typography>
                  <Typography > <b>Rating:</b> Good (236 Likes) </Typography>
                </Stack>
                <Box className="intro-buttons">
                  <Fab aria-label="like">
                    <FavoriteIcon />
                  </Fab>
                </Box>
              </Stack>
            </Box>
            <Box className="content-tabs" sx={{padding: '30px 0px' , backgroundColor: 'white'}}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '20px' }}>
                  <TabList onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="All Products" value="1" />
                    <Tab label="Profile Info" value="2" />
                  </TabList>
                </Box>
                <TabPanel value="1">
                  <SellerProductsPublic vid={vid}/>
                </TabPanel>
                <TabPanel value="2" sx={{ padding: '0px' }}>
                  <SellerProfileinfoPublic storeinfo={storeinfo}/>
                </TabPanel>
              </TabContext>
            </Box>
          </Box>
        </Container>

        <Footer/>
      </Box>
    </>
  )
}

export default SellerProfilePublic