import { Box, Button, Container, Stack, Typography,  TextField } from '@mui/material';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import './Footer.css';
import AmexIcon from '../../assets/footer_icons/Amex.webp';
import MasterIcon from '../../assets/footer_icons/mastercard.webp';
import VisaIcon from '../../assets/footer_icons/VIsa.webp';
import BkashIcon from '../../assets/footer_icons/bkash.webp';
import IconApple from '../../assets/footer_icons/appalebutton.png';
import IconPlay from '../../assets/footer_icons/gplaybutton.png';
import PhoneIcon from '@mui/icons-material/Phone';
import { theme } from '../../theme';
import AuthContext from '../../auth/AuthContext';

export const LeftWrapperBox = styled(Box)(({ theme }) => ({
    maxWidth: '1170px',
    display: 'flex',
    flexWrap: 'wrap',
}));

export const ListTypes = styled(Box)(({ theme }) => ({
    width: '33%',
}));

const Footer_Main = () => {
    
    const { basicInfo } = useContext(AuthContext);

    const { email, phone_number } = (basicInfo && basicInfo.length > 0) ? basicInfo[0] : {};

    return (
        <>
            <Box sx={{ padding: '20px 0', backgroundColor: theme.palette.bgcolor.main, color: theme.palette.textcolor.main }}>
                <Container>
                    <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ justifyContent: 'space-between', gap: { xs: '20px', sm: '0' } }}>
                        <Box sx={{ width: '100%' }}>
                            <LeftWrapperBox>
                                <ListTypes>
                                    <Typography>About Ea-te</Typography>
                                    <ul>
                                        <li><Link to="/about-us">About Us</Link></li>
                                        <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                        <li><Link to="/terms-of-use">Terms of Use</Link></li>
                                    </ul>
                                </ListTypes>
                                <ListTypes>
                                    <Typography>Customer Service</Typography>
                                    <ul>
                                        <li><Link to="/contact">Contact Us</Link></li>
                                        <li><Link to="/faq">FAQ</Link></li>
                                    </ul>
                                </ListTypes>
                            </LeftWrapperBox>
                            <Stack direction="row" alignItems="center">
                                <Typography sx={{ paddingRight: '8px' }}>Payment Methods</Typography>
                                <Box className='paymenticon'>
                                    <img src={AmexIcon} alt='Amex' />
                                    <img src={MasterIcon} alt='Mastercard' />
                                    <img src={VisaIcon} alt='Visa' />
                                    <img src={BkashIcon} alt='Bkash' />
                                </Box>
                            </Stack>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: { xs: 'center', sm: 'flex-end' } }}>
                            <Box component="form" className='getappinputbox' sx={{display: 'flex', alignItems: 'center'}}>
                                <TextField
                                    id="standard-number"
                                    type="number"
                                    placeholder="+880"
                                    variant="standard"
                                    inputProps={{
                                        inputMode: 'numeric',
                                        pattern: '\\d*',
                                    }}
                                />
                                <Button variant='contained' size='small' sx={{fontSize: '9px'}}>Get App</Button>
                            </Box>
                            <Stack direction="row" spacing={2} alignItems="flex-start" my={2}>
                                <Box component={Link} to="/app">
                                    <img src={IconApple} alt='App Store' style={{ width: '100px', height: '30px' }} />
                                </Box>
                                <Box component={Link} to="/app">
                                    <img src={IconPlay} alt='Google Play' style={{ width: '100px', height: '30px' }} />
                                </Box>
                            </Stack>
                            {phone_number && (
                                <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                                    <PhoneIcon />
                                    <a href={`tel:${phone_number}`} style={{ textDecoration: 'none', marginLeft: '5px' }}>
                                        {phone_number}
                                    </a>
                                </Typography>
                            )}
                            {email && (
                                <Typography>
                                    or <a href={`mailto:${email}`}>{email}</a>
                                </Typography>
                            )}
                        </Box>
                    </Stack>
                </Container>
            </Box>
        </>
    );
}

export default Footer_Main;
