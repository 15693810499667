import { Box, Container, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
import {theme} from '../../theme';

const Cities = () => {
    return (
        <>
            <Box
                sx={{
                    backgroundColor: theme.palette.bgcolor.main ,
                    border: '0.5px solid #c2c2c2',
                    borderRight: 'none',
                    borderLeft: 'none',
                    padding: '5px 0',
                    color: theme.palette.textcolor.main ,
                }}
            >
                <Container>
                    <Stack direction="row" sx={{ gap: '30px', justifyContent: 'flex-start'}} >
                        <Typography>Cities:</Typography>
                        <Typography component={Link} to="/" >Dhaka</Typography>
                        <Typography component={Link} to="/" >Chattogram</Typography>
                        <Typography component={Link} to="/" >Jashore</Typography>
                    </Stack>
                </Container>
            </Box >
        </>
    )
}

export default Cities