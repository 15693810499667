import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, ListItemText, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import OrderStatus from './OrderStatus';
import OrderDetails from './OrderDetails';
import { getOrdersByUser } from '../ApiToFatch';
// import AuthContext from '../../auth/AuthContext';

const OrderList = () => {
    const [orders, setOrders] = useState([]);
    const [openStatusDialog, setOpenStatusDialog] = useState(false);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(null);


    useEffect(() => {
        // Fetch orders from the API
        const fetchOrders = async () => {
            try {
                const response = await getOrdersByUser(); // Replace with your API endpoint
                setOrders(response);
            } catch (error) {
                console.error('Error fetching orders:', error);
            }
        };

        fetchOrders();
        // eslint-disable-next-line
    }, []);

    const handleOpenStatusDialog = (orderId) => {
        setSelectedOrderId(orderId);
        setOpenStatusDialog(true);
    };

    const handleCloseStatusDialog = () => {
        setOpenStatusDialog(false);
        setSelectedOrderId(null);
    };

    const handleOpenDetailsDialog = (orderId) => {
        setSelectedOrderId(orderId);
        setOpenDetailsDialog(true);
    };

    const handleCloseDetailsDialog = () => {
        setOpenDetailsDialog(false);
        setSelectedOrderId(null);
    };

    // Add null check here
    const pendingOrders = orders?.filter(order => order.status_title !== 'Delivered') || [];
    const completedOrders = orders?.filter(order => order.status_title === 'Delivered') || [];


    return (
        <Box sx={{ margin: '0 auto' }}>
            <Typography variant="h6" align="center" gutterBottom>
                Pending Orders
            </Typography>
            {pendingOrders.length > 0 ?
                <>
                    <List sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        {pendingOrders.map((order) => (
                            <ListItem key={order.id} sx={{
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' }, border: '1px solid #ccc', borderRadius: '5px', marginBottom: '8px', '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                    cursor: 'pointer' // darker background color on hover
                                }
                            }}>
                                <ListItemText
                                    primary={`Order ID: ${order.id}`}
                                    secondary={`Ordered At: ${new Date(order.created_at).toLocaleString()}`}
                                />
                                <Box sx={{ display: 'flex', gap: { xs: '15px', md: '20px' }, flexDirection: { xs: 'column', sm: 'row' } }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ marginRight: 1 }}
                                        onClick={() => handleOpenStatusDialog(order.id)}
                                    >
                                        Check Status
                                    </Button>
                                    <Button variant="outlined" color="primary" onClick={() => handleOpenDetailsDialog(order.id)}>
                                        Details
                                    </Button>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </> :

                'Nothing to show , please order First'
            }


            <Typography variant="h6" align="center" gutterBottom sx={{ marginTop: 3 }}>
                Completed Orders
            </Typography>
            {completedOrders.length > 0 ?
                <>
                    <List sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        {completedOrders.map((order) => (
                            <ListItem key={order.id} sx={{
                                display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: { xs: 'column', sm: 'row' }, border: '1px solid #ccc', borderRadius: '5px', marginBottom: '8px', '&:hover': {
                                    backgroundColor: '#f0f0f0',
                                    cursor: 'pointer'  // darker background color on hover
                                }
                            }}>
                                <ListItemText
                                    primary={`Order ID: ${order.id}`}
                                    secondary={`Ordered At: ${new Date(order.created_at).toLocaleString()}`}
                                />
                                <Box>
                                    <Button variant="outlined" color="primary" onClick={() => handleOpenDetailsDialog(order.id)}>
                                        Details
                                    </Button>
                                </Box>
                            </ListItem>
                        ))}
                    </List>
                </> :

                'No Completed Order yet!'
            }


            <Dialog open={openStatusDialog} onClose={handleCloseStatusDialog} maxWidth="xs" fullWidth disableScrollLock>
                <DialogContent>
                    {selectedOrderId && <OrderStatus orderId={selectedOrderId} orders={orders} />}
                </DialogContent>
                <DialogActions sx={{ paddingBottom: '20px', paddingRight: '20px' }}>
                    <Button onClick={handleCloseStatusDialog} color="primary" variant='contained'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDetailsDialog} onClose={handleCloseDetailsDialog} maxWidth="md" fullWidth disableScrollLock>
                <DialogTitle>Order Details</DialogTitle>
                <DialogContent>
                    {selectedOrderId && <OrderDetails orderId={selectedOrderId} orders={orders} />}
                </DialogContent>
                <DialogActions sx={{ paddingBottom: '20px', paddingRight: '20px' }}>
                    <Button onClick={handleCloseDetailsDialog} color="primary" variant='contained'>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default OrderList;
