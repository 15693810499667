import React from 'react';
import { Box, Stack, Typography, Grid, Paper, Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import SearchIcon from '@mui/icons-material/Search';
import LayersIcon from '@mui/icons-material/Layers';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DetailsIcon from '@mui/icons-material/ArrowCircleRightOutlined';
import { Link } from 'react-router-dom';
import AddToCartButton from '../custom buttons/AddToCartButton ';
import { ProductDescription , truncateTitle } from '../utils/UtilityFunctions';
import { StockStatusButton } from '../custom buttons/Buttons';
import AddToWishlistButton from '../custom buttons/AddToWishlistButton';


const Product_Item_box = ({ product }) => {
    const [open, setOpen] = React.useState(false);

    if (!product) {
        return null; // Or render a placeholder or loading state
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <Paper className="single-product-box" sx={{ position: 'relative' , display: 'flex', flexDirection: 'column', justifyContent:'space-between', height:'100%' }} >
                {/* product img */}
                <Box className="single-product-img" sx={{position: 'relative'}}>
                    <img src={product.image_url} alt="product img" />
                    <Box className="stock-status" sx={{position: 'absolute', top: '5px', left: '5px'}}>
                        { product.stock_status === true ? (
                            <>
                                <StockStatusButton>
                                    in stock
                                </StockStatusButton>
                            </>
                        ) : (
                            <>
                                 <StockStatusButton>
                                    out of stock
                                </StockStatusButton>
                            </>
                        ) }
                    </Box>
                </Box>
                {/* product info box */}
                <Box className="product-info-wrapper" sx={{ padding: '20px', textAlign: 'center', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    {/* product title */}
                    <div className="product-title">
                        <Typography>{truncateTitle(product.title, 20)}</Typography>
                    </div>
                    {/* product price */}
                    <div className="product-price">৳ {product.saleprice}</div>
                    {/* add to cart button */}
                    <Box>
                        {product && product.stock_status === true ? (
                            <><AddToCartButton product={product} /></>
                        ): (
                            <><AddToCartButton /></>
                        )}
                    </Box>
                </Box>
                {/* hover items box */}
                <Box className='product-hover-items'>
                    <Stack alignItems='flex-end' justifyContent='space-between' height='100%'>
                        <Stack spacing={1} padding={1}>
                            <div className="product-hover-item" onClick={handleClickOpen}>
                                <Tooltip title="Quick view" placement="left">
                                    <SearchIcon />
                                </Tooltip>
                            </div>
                            <div className="product-hover-item">
                                <Tooltip title="Add to wishlist" placement="left">
                                    <FavoriteBorderIcon />
                                </Tooltip>
                            </div>
                            <div className="product-hover-item">
                                <Tooltip title="Compare" placement="left">
                                    <LayersIcon />
                                </Tooltip>
                            </div>
                        </Stack>
                        {/* redirecting to product details page dynamic wid product id*/}
                        <Stack textAlign='center' width='100%'>
                            <Link to={`/product-details/${product.id}`} >
                                <Button variant="text" endIcon={<DetailsIcon />} color='textcolor'>
                                    Details
                                </Button>
                            </Link>
                        </Stack>
                    </Stack>
                </Box>
            </Paper>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth='md' disableScrollLock>
                <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 2, top: 2, color: (theme) => theme.palette.grey[500] }}>
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ padding: '30px' }} >
                    <Grid container sx={{ flexDirection: { xs: 'column', md: 'row' }, justifyContent: { xs: 'center', md: 'space-around' } }}>
                        {/* Image Box */}
                        <Grid item xs={12} md={5}>
                            <Box className="quickview-img-box" >
                                <div className="img-container">
                                    <img src={product.image_url} alt='quickview img' style={{ width: '100%', height: '100%' }} />
                                </div>
                            </Box>
                        </Grid>
                        {/* Details Box */}
                        <Grid item xs={12} md={5}>
                            <Stack className="quickview-details" spacing={2}>
                                <div className="product-title">
                                    <Typography><b>{product.title}</b></Typography>
                                </div>
                                <div className="product-price">
                                    <Typography> <del>৳ {product.regularprice}</del> <b>${product.saleprice}</b></Typography>
                                </div>
                                <div className="product-list-details" style={{ paddingLeft: '30px' }}>
                                    <ProductDescription description={product.description} />
                                </div>
                                <Stack direction="column" className="add-to-cart-section" spacing={2} gap={2}>
                                    {product && <AddToCartButton product={product} />}
                                    {product && <AddToWishlistButton product={product} />}
                                </Stack>
                                <div className="categories">
                                    <Typography><b>Categories:</b> {product.category_name}</Typography>
                                </div>
                                <div className="tags">
                                    <Typography><b>Tag:</b> {product.tag}</Typography>
                                </div>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default Product_Item_box;
