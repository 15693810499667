import { createTheme } from "@mui/material";

export const theme = createTheme({

    palette: {
        primary: {
            main: "#1e73be",
        },
        secondary: {
            main: "#ffbf3f",
            // dark: "#E04F54",
        },
        bgcolor: {
            main: "#3a3a3a",
        },
        textcolor: {
            main: "#ffffff",
            dark: "#3a3a3a",
        },
        bodybgcolor: {
            main: "#F3F4F8",
        },
        boxcolor: {
            main: "#ffffff",
        },
        searchicon: {
            main: "#7a7a7a",
        },
        cityhover: {
            main: "rgb(21, 80, 133)",
        },
        dropdownitemhover: {
            main: "#1e73be",
        },
        cardbgcolor: {
            main: "#f5f5f5",
        },
        darksecondary:{
            main:"#ffbf3f"
        },
        copyrightbgcolor:{
            main: "#f3f3f3"
        },
    },


    components: {

        // MuiTypography: {
        //     defaultProps: {
        //         variantMapping: {
        //             h1: 'h2',
        //             h2: 'h2',
        //             h3: 'h2',
        //             h4: 'h2',
        //             h5: {
        //                 fontSize: '11px',
        //             },
        //             h6: 'h2',
        //             subtitle1: 'h2',
        //             subtitle2: 'h2',
        //             body1: 'span',
        //             body2: 'span',
        //         },
        //     },
        // },

        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: (theme) => theme.palette.textcolor.dark,
                    backgroundColor: 'white',
                    width: '100%', // Updated width
                    height: 40, // Updated height
                    boxSizing: 'border-box', // Updated box-sizing
                    fontSize: 14, // Updated font-size
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    ':hover': {
                        backgroundColor: 'inherit',
                        color: (theme) => theme.palette.textcolor.main ,
                    },
                }
            }
        },

        // MuiButton: {
        //     styleOverrides: {
        //         root: {
        //             ':hover': {
        //                 color: (theme) => theme.palette.textcolor.main ,
        //             },
        //         }
        //     }
        // },

        MuiAccordionSummary:{
            styleOverrides: {
                root: {
                    minHeight: '30px',
                }
            }
        },

    },

});