import {
    ADD_TO_WISHLIST,
    REMOVE_FROM_WISHLIST,
    CLEAR_WISHLIST
} from '../actions/wishlistActions';

const initialState = {
    items: JSON.parse(localStorage.getItem('wishlist')) || []
};

const wishlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_WISHLIST:
            const newItem = action.payload;
            const existsInWishlist = state.items.some(item => item.id === newItem.id);

            if (existsInWishlist) {
                // If item already exists in wishlist, do not add again
                return state;
            } else {
                const updatedItems = [...state.items, newItem];
                localStorage.setItem('wishlist', JSON.stringify(updatedItems));
                return {
                    ...state,
                    items: updatedItems
                };
            }
        case REMOVE_FROM_WISHLIST:
            const filteredItems = state.items.filter(item => item.id !== action.payload);
            localStorage.setItem('wishlist', JSON.stringify(filteredItems));
            return {
                ...state,
                items: filteredItems
            };
        case CLEAR_WISHLIST:
            localStorage.removeItem('wishlist');
            return {
                ...state,
                items: []
            };
        default:
            return state;
    }
};

export default wishlistReducer;
