import React, { useContext , useState } from 'react';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Stack, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import InputBase from '@mui/material/InputBase';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, updateCartItem } from '../../redux_store/actions/cartActions';
import { theme } from '../../theme';
import { truncateTitle } from '../utils/UtilityFunctions';
import { useNavigate } from 'react-router-dom';
import LoginDialog from '../Auth_Forms/LoginDialog';  // Import the LoginDialog component
import AuthContext from '../../auth/AuthContext';

const CartDrawer = ({ openCartDrawer, handleDrawerOpen, handleDrawerClose }) => {
    const cartItems = useSelector(state => state.cart.items);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginDialogOpen, setLoginDialogOpen] = React.useState(false);
    // eslint-disable-next-line
    const { isLoggedIn, setIsLoggedIn , username , setUsername } = useContext(AuthContext);
    // const [username, setUsername] = useState(''); // Added state for username
    // eslint-disable-next-line
    const [alert, setAlert] = useState(null);

    const calculateTotal = () => {
        return cartItems.reduce((total, item) => total + item.product.saleprice * item.quantity, 0);
    };

    const increaseQuantity = (productId) => {
        const currentItem = cartItems.find(item => item.product.id === productId);
        if (currentItem) {
            const newQuantity = currentItem.quantity + 1;
            dispatch(updateCartItem(productId, newQuantity));
        }
    };

    const decreaseQuantity = (productId) => {
        const currentItem = cartItems.find(item => item.product.id === productId);
        if (currentItem && currentItem.quantity > 1) {
            const newQuantity = currentItem.quantity - 1;
            dispatch(updateCartItem(productId, newQuantity));
        }
    };

    const removeItem = (productId) => {
        dispatch(removeFromCart(productId));
    };

    const handlePlaceOrder = () => {
        if (isLoggedIn === true) {
            handleDrawerClose();
            navigate('/checkout');
        } else {
            setLoginDialogOpen(true);
        }
    };

    const handleLoginDialogClose = () => {
        setLoginDialogOpen(false);
    };

    const showAlert = (severity, message) => {
        setAlert({ severity, message });
        setTimeout(() => {
            setAlert(null);
        }, 5000);
    };

    return (
        <>
            {!openCartDrawer && (
                <div className="cart-button" onClick={handleDrawerOpen}>
                    <div className="item-count">
                        <div className="icon">
                            <ShoppingBagIcon />
                        </div>
                        <div className="item-count-number">
                            <span>{cartItems.length} Items</span>
                        </div>
                    </div>
                    <div className="total" style={{ paddingTop: '5px' }}>
                        <span>৳ {calculateTotal()}</span>
                    </div>
                </div>
            )}

            <Drawer
                variant="persistent"
                anchor="right"
                open={openCartDrawer}
                sx={{
                    '& .MuiDrawer-paper': {
                        top: '53px',
                        width: { xs: '100%', sm: '400px' },
                        height: '-webkit-fill-available',
                        padding: '0',
                        paddingBottom: { xs: '40px', sm: '0' },
                    }
                }}
            >
                <Stack className="cart-drawer-header" direction="row" justifyContent="space-between" alignItems="center" padding="10px">
                    <Stack className="cart-header-item-count" direction="row" alignItems="center" spacing={1}>
                        <div className="icon">
                            <ShoppingBagIcon />
                        </div>
                        <div className="item-count-number">
                            <Typography>{cartItems.length} Items</Typography>
                        </div>
                    </Stack>
                    <Button variant='outlined' size='small' color='secondary' onClick={handleDrawerClose}>Close</Button>
                </Stack>

                <Box className="cart-drawer-item-list" sx={{ paddingTop: '20px', maxHeight: '100%', overflowY: 'scroll' }}>
                    <Stack className="cart-draweritems-wrapper">
                        {cartItems.map((item) => (
                            <Stack key={item.product.id} className="cart-drawer-items" direction="row" alignItems="center" spacing={2} justifyContent="space-between" padding="8px">
                                <Stack className="countity" direction="column" spacing={1} alignItems="center">
                                    <KeyboardArrowUpIcon sx={{ cursor: 'pointer' }} onClick={() => increaseQuantity(item.product.id)} />
                                    <Typography>{item.quantity}</Typography>
                                    <KeyboardArrowDownIcon sx={{ cursor: 'pointer' }} onClick={() => decreaseQuantity(item.product.id)} />
                                </Stack>
                                <div className="cart-p-picture">
                                    <img src={item.product.image_url} alt='product img' />
                                </div>
                                <Stack direction="column" className="cart-drawer-item-name">
                                    <Typography variant='span'>{truncateTitle(item.product.title, 20)}</Typography>
                                    <Typography variant='span'>৳ {item.product.saleprice} / {item.product.weight}</Typography>
                                </Stack>
                                <div className="amout">
                                    <Typography>৳ {item.product.saleprice * item.quantity}</Typography>
                                </div>
                                <Button color='secondary' onClick={() => removeItem(item.product.id)}> <CloseIcon /> </Button>
                            </Stack>
                        ))}
                    </Stack>
                </Box>

                <Box className="bottom-section" sx={{ marginTop: 'auto' }}>
                    <Box className="acordian-box">
                        <Accordion sx={{ backgroundColor: theme.palette.bgcolor.main, color: theme.palette.textcolor.main }}>
                            <AccordionSummary
                                expandIcon={<ArrowDropDownIcon sx={{ color: 'white' }} />}
                                aria-controls="panel2-content"
                                id="panel2-header"
                                sx={{
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary.main,
                                    },
                                }}
                            >
                                <Typography> Have a special code? </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
                                    <div className="cart-coupon">
                                        <InputBase
                                            placeholder="Coupon code"
                                            inputProps={{ 'aria-label': 'coupon' }}
                                            sx={{ height: '33px' }}
                                        />
                                    </div>
                                    <Button variant='contained' size='small'
                                        sx={{
                                            height: '33px',
                                            backgroundColor: theme.palette.secondary.main,
                                            color: theme.palette.textcolor.dark,
                                            '&:hover': {
                                                color: theme.palette.textcolor.main,
                                            },
                                        }}
                                    >
                                        Go
                                    </Button>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>
                    </Box>

                    <Stack direction="row" my={2} sx={{ height: '40px', paddingX: '20px' }}>
                        <Button
                            variant='contained'
                            fullWidth
                            sx={{ borderRadius: '0', color: 'white', backgroundColor: theme.palette.primary.main }}
                            onClick={handlePlaceOrder}
                            disabled={cartItems.length < 1}  // Disable button if cartItems is less than 1
                        >
                            Place Order
                        </Button>
                        <Button variant='contained' fullWidth sx={{ borderRadius: '0', color: 'black' }} color='darksecondary'>
                            ৳ {calculateTotal()}
                        </Button>
                    </Stack>
                </Box>
            </Drawer>

            <LoginDialog
                open={loginDialogOpen}
                handleClose={handleLoginDialogClose}
                navigate={navigate}
                showAlert={showAlert}
                setIsLoggedIn={setIsLoggedIn}
                setUsername={setUsername}
            />
        </>
    );
};

export default CartDrawer;
