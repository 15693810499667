import React from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import BlogsImg from '../assets/blogs-img.jpg'
import { Stack , Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';

const BlogsCard = () => {

    // Function to limit the number of characters in blog details
    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        } else {
            return text;
        }
    };


    return (
        <>
            <Card sx={{ maxWidth: 345 }}>
                <Box
                    className='card-img-hover'
                    sx={{
                        transition: 'all 1.5s ease',
                        cursor: 'pointer',
                        overflow: 'hidden',
                        height: '180px',
                    }}
                >
                    <img src={BlogsImg} alt="blogs-img" />
                </Box>
                <CardContent>
                    <Link to="/">
                        <Typography gutterBottom variant="h5" component="div">
                            IPX waterproof rating guide
                        </Typography>
                    </Link>
                    <Typography variant="body2" color="text.secondary" className='blog-details'>
                        {/* Truncate blog details to max 200 characters */}
                        {truncateText(
                            '“IPX Waterproof Rating Guide,” it likely involves the waterproof or water-resistant capabilities of a device or product. Below is a detailed description of the IPX waterproof rating guide: When considering.',
                            90
                        )}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <PersonIcon />
                        <Typography variant='span'>
                            Author Name
                        </Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <AccessTimeIcon />
                        <Typography variant='span'>
                            January 28, 2024
                        </Typography>
                    </Stack>
                </CardActions>
            </Card>
        </>
    )
}

export default BlogsCard