import React, { useState, useContext } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneIcon from '@mui/icons-material/PhoneAndroidOutlined';
import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';
import AuthContext from '../../auth/AuthContext';
import { apiUrl, getSingleUser, handleRegisterSubmit } from '../ApiToFatch';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const LoginDialog = ({ open, handleClose, navigate, showAlert, handleLoginDialogClose }) => {

  const { setIsLoggedIn, setUsername } = useContext(AuthContext);
  const [loginMethod, setLoginMethod] = useState('phone');

  const [errors, setErrors] = useState({
    username: '',
    email: '',
    password: '',
  });

  const toggleLoginMethod = () => {
    setLoginMethod((prevMethod) => (prevMethod === 'phone' ? 'email' : 'phone'));
  };

  const handleLoginSubmit = async (loginCredentials) => {
    try {
      const response = await fetch(`${apiUrl}/api/login/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginCredentials),
        credentials: 'include'
      });

      // eslint-disable-next-line
      const responseData = await response.json();

      if (response.ok) {
        // Cookies will be set by the backend, no need to handle them here

        setIsLoggedIn(true);
        handleClose();

        // Fetch user details using cookies
        const userData = await getSingleUser();
        setUsername(userData);

        // Show success alert
        showAlert('success', 'Login successful!');
      } else {
        // Login failed, handle accordingly
        showAlert('error', 'Login failed! Please check your details and try again.');
      }
    } catch (error) {
      // Handle fetch error
      showAlert('error', 'Login failed! Please try again later.');
    }
  };



  return (
    <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} disableScrollLock>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Login
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Stack direction="column" spacing={2}>
          <Button variant="contained" color="primary">
            Sign Up or Login with Facebook
          </Button>
          <Button
            variant="outlined"
            startIcon={loginMethod === 'phone' ? <EmailOutlinedIcon /> : <PhoneIcon />}
            onClick={toggleLoginMethod}
          >
            {loginMethod === 'phone' ? 'Login' : 'Register'}
          </Button>
        </Stack>

        <Box paddingY={2}>
          <Typography textAlign="center"><b>or</b></Typography>
        </Box>

        {loginMethod === 'phone' ? (
          <RegisterForm
            handleRegisterSubmit={(credentials) =>
              handleRegisterSubmit(credentials, navigate, showAlert, handleClose, setErrors)
            }
            onRegisterChange={(e) => { }}
            setErrors={setErrors}
            errors={errors}
            navigate={navigate}
            showAlert={showAlert}
            handleClose={handleClose}
          />
        ) : (
          <LoginForm
            handleLoginSubmit={handleLoginSubmit}
            onLoginChange={(e) => { }}
          />
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default LoginDialog;
