import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import AppBackground from '../../assets/downloadAppBackground.png'
import AppMobile from '../../assets/downloadApp.png'
import { ReactComponent as IconApple } from '../../assets/store-apple.svg';
import { ReactComponent as IconPlay } from '../../assets/google-play-badge.svg';

const AppDownload = () => {
    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url(${AppBackground})`,
                    backgroundSize: 'cover',
                    marginBottom: '40px',
                    paddingTop: { xs: '40px', lg: '0' }
                }}
            >
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexBasis: '50%' }}>
                        <Typography gutterBottom variant="h6" component="div">
                            Download The "ea-te" App Now!
                        </Typography>
                        <Typography variant="body2" textAlign="center" sx={{ width: '70%' }}>
                            Get 5% off on your first order through the "ea-te" app and make your shopping experience even smoother!
                        </Typography>

                        <Stack direction="row" spacing={2}>
                            <Box component={Link} to="/app">
                                <IconApple style={{ width: '100px', height: '100px' }} />
                            </Box>
                            <Box component={Link} to="/app">
                                <IconPlay style={{ width: '100px', height: '100px' }} />
                            </Box>
                        </Stack>
                    </Box>
                    <Box sx={{ flexBasis: '50%' }}>
                        <Box sx={{ width: { xs: '100%', lg: '100%' } }}>
                            <img src={AppMobile} alt='appmobile' style={{ width: '100%', height: 'auto' }} />
                        </Box>
                    </Box>
                </Stack>
            </Box>
        </>
    )
}

export default AppDownload