import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Home from './pages/Home';
import './App.css';
import Navbar from './components/appbar/Navbar';
import LeftSideDrawer from './components/left drawer/Left_SideDrawer';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './theme';
import CartDrawer from './components/cart drawer/CartDrawer';
import ProductListpage from './pages/Product_List_page';
import ProductsByCategory from './pages/ProductsByCategory';
import LiveChat from './components/utils/LiveChat';
import AllCategories from './pages/All_Categories';
import ProductDetails from './pages/ProductDetails';
import SubCategory from './pages/SubCategory';
import MobileNavBottom from './components/MobileNavBottom';
import ScrollToTop from './components/utils/ScrollToTop';
import PopularProducts from './pages/PopularProducts';
import NewArival from './pages/NewArival';
import Checkout from './pages/Checkout';
import MyAccount from './pages/MyAccount';
import AuthContext from './auth/AuthContext';
import OrderSuccess from './pages/OrderSuccess';
import InformativePage from './pages/InformativePage';
import { Box } from '@mui/material';
import SellerLandingPage from './pages/seller/SellerLandingPage';
import SellerRegistration from './pages/seller/SellerRegistration';
import SellerLogin from './pages/seller/SellerLogin';
import SellerProfile from './pages/seller/SellerProfile';
import SellerProfilePublic from './pages/seller/public view/SellerProfilePublic';
import FavoritesProducts from './pages/FavoritesProducts';
// import OrderStatus from './components/order/OrderStatus';
// import { HelmetProvider } from 'react-helmet-async';

function App() {
  const isLargeScreen = useMediaQuery({ minWidth: 1366 });
  const [isDrawerOpen, setIsDrawerOpen] = useState(isLargeScreen);
  const { isLoggedIn, username } = useContext(AuthContext);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [openCartDrawer, setOpenCartDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpenCartDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenCartDrawer(false);
  };

  useEffect(() => {
    setIsDrawerOpen(isLargeScreen);
  }, [isLargeScreen]);



  return (
    <ThemeProvider theme={theme}>

      <BrowserRouter>
        <ScrollToTop />
        <div id='layout' className={isDrawerOpen ? 'drawer-open' : 'drawer-closed'} >
          <nav>
            <Navbar onDrawerToggle={handleDrawerToggle} />
          </nav>

          <div id='left-sidebar'>
            <LeftSideDrawer open={isDrawerOpen} />
          </div>

          <div id='cart-sidebar'>
            <CartDrawer openCartDrawer={openCartDrawer} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} />
          </div>

          <div id="live-chat">
            <LiveChat />
          </div>

          <main>
            <Box className="container" sx={{ marginTop: { xs: '70px', sm: '0px' } }}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/categories" element={<AllCategories />} />
                <Route path="/categories/:categoryName" element={<SubCategory />} />
                <Route path="/products/:subcatName" element={<ProductListpage />} />
                <Route path="/categories/products/:slug" element={<ProductsByCategory />} />
                <Route path="/product-details/:id" element={<ProductDetails />} />
                <Route path="/products/popular" element={<PopularProducts />} />
                <Route path="/products/newarival" element={<NewArival />} />
                <Route path="/categories/products" element={<PopularProducts />} />
                <Route path="/categories/favourites" element={<PopularProducts />} />
                <Route path="/products/favourites" element={<FavoritesProducts />} />
                <Route path="/about-us" element={<InformativePage />} />
                <Route path="/privacy-policy" element={<InformativePage />} />
                <Route path="/terms-of-use" element={<InformativePage />} />
                <Route path="/contact" element={<InformativePage />} />
                <Route path="/faq" element={<InformativePage />} />
                <Route path="/seller-store/:vid" element={<SellerProfilePublic />} />


                {!isLoggedIn || username?.role !== 'vendors' ? (
                  <>
                    <Route path="/sell-on-eate" element={<SellerLandingPage />} />
                    <Route path="/seller-register" element={<SellerRegistration />} />
                    <Route path="/seller-login" element={<SellerLogin />} />
                  </>
                ) : (
                  <>
                    <Route path="/sell-on-eate" element={<Navigate to="/" replace />} />
                    <Route path="/seller-register" element={<Navigate to="/" replace />} />
                    <Route path="/seller-login" element={<Navigate to="/" replace />} />
                  </>
                )}

                { isLoggedIn && username?.role === 'vendors' ? (
                  <>
                    <Route path="/seller-profile" element={<SellerProfile />} />
                  </>
                ) : (
                  <>
                    <Route path="/seller-profile" element={<Navigate to="/" replace />} />
                  </>
                )}

                {isLoggedIn === true && username?.role !== 'vendors' ? (
                  <>
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/profile" element={<MyAccount />} />
                    <Route path="/order-success" element={<OrderSuccess />} />
                    {/* <Route path="/order-status/:orderId" element={<OrderStatus />} /> */}
                  </>
                ) : (
                  <>
                    <Route path="/checkout" element={<Navigate to="/" replace />} />
                    <Route path="/profile" element={<Navigate to="/" replace />} />
                    <Route path="/order-success" element={<Navigate to="/" replace />} />
                    {/* <Route path="/order-status/:orderId" element={< Navigate to="/" replace />} /> */}
                  </>
                )}
                
              </Routes>
            </Box>
          </main>

          <div className="mobile-nav-bottom">
            <MobileNavBottom onDrawerToggle={handleDrawerToggle} handleDrawerOpen={handleDrawerOpen} />
          </div>

        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
