import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import BreadCrumb from '../components/BreadCrumb';
import { Box, Button, Container, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import ProductItemBox from '../components/product realated/Product_Item_box';
import Footer from '../components/Footer/Footer';
// tabs import
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { apiUrl } from '../components/ApiToFatch';
import AddToCartButton from '../components/custom buttons/AddToCartButton ';
import { StockStatusButton } from '../components/custom buttons/Buttons';
import SeoInfo from '../components/seo/SeoInfo';
import { useLocation } from 'react-router-dom';
import AddToWishlistButton from '../components/custom buttons/AddToWishlistButton';
import ReviewForm from '../components/product realated/ReviewForm';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { getSingleVendorinfo } from '../components/ApiToFatch';

const ProductDetails = () => {

    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [category, setCategory] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [storeinfo, setStoreinfo] = useState([]);

    useEffect(() => {
        fetchReviews();
        // eslint-disable-next-line
    }, []);

    const fetchReviews = async () => {
        try {
            const response = await fetch(`${apiUrl}/reviews/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const responseBody = await response.json();
                setReviews(responseBody);
            } else {
                console.error("HTTP error! Status:", response.status);
                return []; // Return an empty array on error
            }

        } catch (error) {
            console.error('Error fetching reviews:', error);
        }
    };

    const addReview = (newReview) => {
        setReviews(prevReviews => [...prevReviews, newReview]);
    };


    // seo url
    const location = useLocation();
    const currentUrl = `${window.location.origin}${location.pathname}`;

    // fetch product according to id found in url parameters and product category and related products of that category.
    useEffect(() => {
        const getSIngleProductData = async () => {
            try {
                const response = await fetch(`${apiUrl}/products/${id}/`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const responseBody = await response.json();
                    setProduct(responseBody);
                    // Fetch category data based on product category ID
                    const categoryResponse = await fetch(`${apiUrl}/categories/${responseBody.categoryid}/`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                    if (categoryResponse.ok) {
                        const categoryData = await categoryResponse.json();
                        setCategory(categoryData);

                        // Fetch related products based on category ID
                        const relatedProductsResponse = await fetch(`${apiUrl}/products/category/${categoryData.id}/`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        if (relatedProductsResponse.ok) {
                            const relatedProductsData = await relatedProductsResponse.json();
                            // Filter out the current product from the related products
                            const filteredRelatedProducts = relatedProductsData.filter(product => product.id !== responseBody.id);
                            // Limit to the first 4 related products
                            const limitedRelatedProducts = filteredRelatedProducts.slice(0, 4);
                            //set realated products
                            setRelatedProducts(limitedRelatedProducts);
                            console.log(relatedProducts);
                        } else {
                            console.error("HTTP error! Status:", relatedProductsResponse.status);
                        }
                    } else {
                        console.error("HTTP error! Status:", categoryResponse.status);
                    }
                } else {
                    console.error("HTTP error! Status:", response.status);
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        getSIngleProductData();
        // eslint-disable-next-line
    }, [id]);

    // for quickview model
    // eslint-disable-next-line
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    // breadcrumb Links
    const links = [
        { to: '/categories', label: 'All Categories' },
        category && { to: `/categories/${category.slug}`, label: category.name }
    ].filter(Boolean); // Filter out null values if category is not yet fetched

    // for description-review-tab
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const vid = product ? product.vendor : null;

    // Fetch store information only if vendor ID exists
    useEffect(() => {
        const fetchData = async () => {
            if (vid) {
                try {
                    const storeinfoData = await getSingleVendorinfo(vid);
                    if (storeinfoData) {
                        setStoreinfo(storeinfoData);
                    } else {
                        setStoreinfo(null);  // Explicitly set to null if no store info
                    }
                } catch (error) {
                    console.error('Error fetching store info:', error);
                    setStoreinfo(null);  // Handle error by setting storeinfo to null
                }
            }
        };

        fetchData();
    }, [vid]);

    // if product not found show loading
    if (!product) {
        return <div>Loading...</div>;
    }

    // complex description handler for simplyfy the output
    const ProductDescription = ({ description }) => {
        return (
            <div className="product-description">
                <Typography component="div" dangerouslySetInnerHTML={{ __html: description }} />
            </div>
        );
    };

    return (
        <>
            <SeoInfo
                title={`Ea-te.com: ${product.title}`}
                // description={product.sm_description}
                description='sm description'
                name="Ea-te"
                type="product"
                sm_image={product.sm_image}
                url={currentUrl}
            />
            <div className="product-detail-container">
                <BreadCrumb links={links} />
                <Container>
                    <Box className="product-details-wrapper" sx={{ paddingY: '40px' }}>
                        {/* main product details section start */}
                        <Grid container sx={{ flexDirection: { xs: 'column', sm: 'row' } }} spacing={3}>
                            {/* Image Box */}
                            <Grid item xs={12} sm={6}>
                                <Box className="quickview-img-box" sx={{ position: 'relative' }}>
                                    <div className="img-container">
                                        <img src={product.image_url} alt='quickview img' />
                                    </div>
                                    <Stack direction="row" className="small-images" justifyContent="center">
                                        <div className="small-img-box">
                                            <img src={product.image_url} alt='quickview img' style={{ width: '100%', height: '100%' }} />
                                        </div>
                                    </Stack>
                                    <Box className="stock-status" sx={{ position: 'absolute', top: '5px', left: '5px' }}>
                                        {product.stock_status === true ? (
                                            <>
                                                <StockStatusButton>
                                                    in stock
                                                </StockStatusButton>
                                            </>
                                        ) : (
                                            <>
                                                <StockStatusButton>
                                                    out of stock
                                                </StockStatusButton>
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            </Grid>
                            {/* Details Box */}
                            <Grid item xs={12} sm={6}>
                                <Stack className="quickview-details" spacing={2} padding="20px">
                                    <div className="product-title">
                                        <Typography><b>{product.title}</b></Typography>
                                    </div>
                                    <div className="product-price">
                                        <Typography> <del>৳ {product.regularprice}</del> ৳ {product.saleprice}</Typography>
                                    </div>
                                    <div className="product-list-details" style={{ paddingLeft: '30px' }}>
                                        <ProductDescription description={product.description} />
                                    </div>
                                    <Stack direction="row" className="add-to-cart-section" spacing={2}>
                                        {product && <AddToCartButton product={product} />}
                                    </Stack>
                                    <Box className="add-to-wishlist-button">
                                        {product && <AddToWishlistButton product={product} />}
                                    </Box>
                                    <Box className="categories">
                                        <Typography>Categories: {product.category_name}</Typography>
                                    </Box>
                                    <div className="tags">
                                        <Typography>Tag: {product.tag}</Typography>
                                    </div>
                                    <Stack direction="row" spacing={1} className='socialicon'>
                                        <Link to="/" >  <i className="fa-brands fa-facebook"></i>  </Link>
                                        <Link to="/" >  <i className="fa-brands fa-twitter"></i>  </Link>
                                        <Link to="/" >  <i className="fa-brands fa-instagram"></i>  </Link>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* main product details section ends */}
                        {/* product description and rivew tab section start*/}
                        <Box className="description-review-tab">
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                                        <Tab label="DESCRIPTION" value="1" />
                                        <Tab label="REVIEWS" value="2" />
                                        <Tab label="Seller info" value="3" />
                                    </TabList>
                                </Box>
                                <TabPanel value="1">
                                    <Stack spacing={2} direction="column" className='tabpanel-container'>
                                        <div className="description">
                                            <Typography><b>DESCRIPTION</b></Typography>
                                        </div>
                                        <div className="item-description">
                                            <Typography component="div" dangerouslySetInnerHTML={{ __html: product.productdetails }} />
                                        </div>
                                    </Stack>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Stack spacing={2} direction="column" className='tabpanel-container'>
                                        <div className="reviews">
                                            <Typography>REVIEWS</Typography>
                                        </div>
                                        <div className="review-count">
                                            {reviews.length > 0 ? (
                                                <>
                                                    <Typography variant="h6">{`Total Reviews: ${reviews.length}`}</Typography>
                                                    {reviews.map(review => (
                                                        <Box key={review.id} sx={{ display: 'flex', gap: '20px', alignItems: 'center', padding: '5px 20px' }} >
                                                            <Typography variant="subtitle1">{`Name: ${review.name}`}</Typography>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                                                                <Typography variant="subtitle1">{`Rating: ${review.rating}`} </Typography>
                                                                <StarBorderIcon />
                                                            </Box>
                                                            <Typography variant="body1">{`Review: ${review.review}`}</Typography>
                                                        </Box>
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    <Typography>There are no reviews yet.</Typography>
                                                    <div>
                                                        <Typography>Be the first to review “{product.title}”</Typography>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <ReviewForm ProductId={id} addReview={addReview} />
                                    </Stack>
                                </TabPanel>
                                <TabPanel value="3">
                                    {product?.vendor && storeinfo ? (
                                        <Paper elevation={3} sx={{ padding: '20px' }}>
                                            <Box className="shop-name" sx={{ marginBottom: '20px' }}>
                                                <Typography>Sold By</Typography>
                                                <Stack spacing={2} direction={'row'} sx={{ marginTop: '12px' }} alignItems={'center'}>
                                                    <Box className="intro-img" sx={{ width: '60px', height: '60px' }}>
                                                        <img src={storeinfo?.logo_image} alt="shop-logo" style={{ width: '100%', height: '100%' }} />
                                                    </Box>
                                                    <Typography variant='h6'>{storeinfo?.shop_name}</Typography>
                                                </Stack>
                                            </Box>
                                            <Divider />
                                            <Box className="shop-info">
                                                <Grid container sx={{ padding: '20px' }}>
                                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                                        <Box className="profile-info-inner-texts">
                                                            <Typography variant='h6'>Joined</Typography>
                                                            <Typography>New Seller</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                                        <Box className="profile-info-inner-texts info-with-left-border">
                                                            <Typography variant='h6'>Shipped on Time</Typography>
                                                            <Typography sx={{ color: 'green' }}>100%</Typography>
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                                        <Box className="profile-info-inner-texts info-with-left-border">
                                                            <Typography variant='h6'>Product Type</Typography>
                                                            <Typography>{storeinfo?.product_type_name}</Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Divider />
                                            <Box className="goto-shop-button" sx={{ marginTop: '20px', textAlign: 'center' }}>
                                                <Button component={Link} to={`/seller-store/${vid}`} variant='text'>Go to Store</Button>
                                            </Box>
                                        </Paper>
                                    ) : (
                                        <Typography variant="body1">No seller information available.</Typography>
                                    )}
                                </TabPanel>
                            </TabContext>
                        </Box>
                        {/* product description and rivew tab section ends*/}
                        {/* related products section start */}
                        <Box className="related-product-container" marginY={5}>
                            <Typography variant='h5'>Related Products</Typography>
                            {relatedProducts.length < 0 ? (
                                <Box sx={{ margin: "40px" }}>No related products available for this category.</Box>
                            ) : (
                                <Grid container className="related-products" spacing={4} pt={2}>
                                    {relatedProducts.map((relatedProduct) => (
                                        <Grid key={relatedProduct.id} item xs={12} sm={4} md={3}>
                                            <ProductItemBox product={relatedProduct} handleClickOpen={handleClickOpen} />
                                        </Grid>
                                    ))}
                                </Grid>
                            )}
                        </Box>
                        {/* related products section ends */}
                    </Box>
                </Container>

                <Footer />
            </div>
        </>
    );
}

export default ProductDetails;
