import { Box, Container } from '@mui/material'
import React from 'react'
import BreadCrumb from '../components/BreadCrumb'
import Footer from '../components/Footer/Footer'
import SeoInfo from '../components/seo/SeoInfo';
import { Link } from 'react-router-dom';

const OrderSuccess = () => {
    return (
        <>
            <SeoInfo
                title='Order Success'
                description='Order Success Confirmation page on Ea-te'
                creatorname='Ea-te'
            />
            <Box className="order-success-conatiner" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh' }}>
                <BreadCrumb page_title="Order Success" />
                <Container>
                    <Box className="order-success-main" sx={{ textAlign: 'center' }}>
                        <Box className="success-msg" >
                            <h1>Congratulations</h1>
                            <h3>Your Order has been successfuly placed.</h3>
                        </Box>
                        <Box className="order-success-details">
                            <Link to="/profile">
                                Check Order Details
                            </Link>
                        </Box>
                    </Box>
                </Container>
                <Footer />
            </Box>
        </>
    )
}

export default OrderSuccess