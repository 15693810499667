import React, { useState, useEffect, useContext } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { Link } from "react-router-dom";
import MailIcon from "@mui/icons-material/Mail";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  AppTypeButton,
  AppTypeButtonOutlined,
  OfferCountButton,
  FeedbackButton,
  SiteTypeBox,
} from "../custom buttons/Buttons";
import MedicationIcon from "@mui/icons-material/Medication";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import Divider from "@mui/material/Divider";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FavoriteIcon from "@mui/icons-material/Favorite";
import BoltIcon from "@mui/icons-material/Bolt";
import HelpIcon from "@mui/icons-material/Help";
import ForumIcon from "@mui/icons-material/Forum";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import CleanHandsIcon from "@mui/icons-material/CleanHands";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import CountertopsIcon from "@mui/icons-material/Countertops";
import PrintIcon from "@mui/icons-material/Print";
import PetsIcon from "@mui/icons-material/Pets";
import { theme } from "../../theme";
import CategoryIcon from '@mui/icons-material/Category';
import { getAllCategories } from "../ApiToFatch";
import AuthContext from "../../auth/AuthContext";

export const drawerItemsone = [
  {
    name: "Favourites",
    icon: <FavoriteIcon />,
    path: "/products/favourites",
  },
  {
    name: "All Categories ",
    icon: <BoltIcon />,
    path: "/categories",
  },
  {
    name: "Popular",
    icon: <MailIcon />,
    path: "/products/popular",
  },
];

export const drawerItems = [
  {
    name: "Smart Watch",
    icon: <FastfoodIcon />,
    path: "/products/smart-watch",
    arrow: <KeyboardArrowRightIcon />,
    subcategory: [
      {
        name: "Watch Strap",
        path: "/products/watch-strap",
      },
      {
        name: "Amazfit",
        path: "/products/amazfit",
      },
      {
        name: "Colmi",
        path: "/products/colmi",
      },
      {
        name: "DIOC",
        path: "/products/dioc",
      },
      {
        name: "Geeoo",
        path: "/products/geeoo",
      },
      {
        name: "Havit",
        path: "/products/havit",
      },
      {
        name: "JOYROOM",
        path: "/products/joyroom",
      },
    ],
  },
  {
    name: "Headphones & Earphone",
    icon: <CleaningServicesIcon />,
    path: "/products/headphone-earphone",
    arrow: <KeyboardArrowRightIcon />,
    subcategory: [
      {
        name: "headphone",
        path: "/products/headphone",
      },
      {
        name: "Earphones",
        path: "/products/earphones",
      },
      {
        name: "Buds",
        path: "/products/buds",
      },
      {
        name: "Gaming Headphones",
        path: "/products/gaming-headphones",
      },
    ],
  },
  {
    name: "Bluetooth Speaker",
    icon: <CleanHandsIcon />,
    path: "/products/bluetooth-speaker",
    arrow: <KeyboardArrowRightIcon />,
    subcategory: [
      {
        name: "Awei",
        path: "/products/awei",
      },
      {
        name: "Havitt",
        path: "/products/havitt",
      },
    ],
  },
  {
    name: "Gadgets & lifestyle",
    icon: <MedicationLiquidIcon />,
    path: "/products/gadgets-lifestyle",
    arrow: <KeyboardArrowRightIcon />,
    subcategory: [
      {
        name: "Action Camera",
        path: "/products/action-camera",
      },
      {
        name: "Cable",
        path: "/products/cable",
      },
    ],
  },
  {
    name: "Computer & Office",
    icon: <ChildFriendlyIcon />,
    path: "/products/computer-office",
    arrow: <KeyboardArrowRightIcon />,
    subcategory: [
      {
        name: "Keyboard",
        path: "/products/keyboard",
      },
      {
        name: "HDMI cable",
        path: "/products/hdmi-cable",
      },
    ],
  },
  {
    name: "Home Appliance",
    icon: <CountertopsIcon />,
    path: "/products/home-appliance",
    arrow: <KeyboardArrowRightIcon />,
    subcategory: [
      {
        name: "Kitchen",
        path: "/products/kitchen",
      },
      {
        name: "Juicer",
        path: "/products/Juicer",
      },
    ],
  },
  {
    name: "Security Surveillance",
    icon: <PrintIcon />,
    path: "/products/security",
    arrow: <KeyboardArrowRightIcon />,
    subcategory: [
      {
        name: "Router",
        path: "/products/router",
      },
      {
        name: "Web Camera",
        path: "/products/web-camera",
      },
    ],
  },
  {
    name: "Bag & Luggage",
    icon: <PetsIcon />,
    path: "/products/bag-luggage",
    arrow: <KeyboardArrowRightIcon />,
    subcategory: [
      {
        name: "BANGE",
        path: "/products/bange",
      },
      {
        name: "TELESIN",
        path: "/products/telesin",
      },
    ],
  },
];


const baseUrl = '/categories/';
export const generatePath = (name) => {
  // Replace special characters with dashes and convert to lowercase
  return encodeURIComponent(name.toLowerCase().replace(/[^\w\s-]/g, '').replace(/\s+/g, '-'));
};


const Left_SideDrawer = ({ open }) => {

  const { isLoggedIn, username } = useContext(AuthContext);
  const [categories, setCategories] = useState([]);

  // fetch all category with subcategory data 
  useEffect(() => {
    const fetchData = async () => {
      try {

        const categoriesData = await getAllCategories();
        setCategories(categoriesData);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const offersItems = [
    {
      name: "Offers",
      path: "/Offers",
      icon: (
        <OfferCountButton variant="outlined" color="secondary">
          12
        </OfferCountButton>
      ),
    },
    {
      name: "Daily deals",
      icon: <LocalOfferOutlinedIcon />,
      path: "/deals",
    },
    // Conditionally add "Become a Seller" item
    ...(!isLoggedIn || username?.role !== 'vendors'
      ? [
        {
          name: "Become a Seller",
          path: "/sell-on-eate",
        },
      ]
      : []),
  ];

  const [openSubcategory, setOpenSubcategory] = useState(
    Array(categories.length).fill(false)
  );

  const toggleSubcategory = (index) => {
    const updatedOpenSubcategory = [...openSubcategory];
    updatedOpenSubcategory[index] = !updatedOpenSubcategory[index];
    setOpenSubcategory(updatedOpenSubcategory);
  };



  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          top: "53px", // Adjust this value according to your appbar height
          width: "250px",
          // zIndex: 0, // Ensuring that the drawer is behind the appbar
          padding: "20px 0 0",
          paddingBottom: "0",
          height: "-webkit-fill-available",
        },
      }}
    >
      <Grid container height={"100%"} sx={{ overflow: "hidden" }}>
        {/* menu items scrollable start */}
        <Grid
          item
          xs={12}
          sx={{
            height: "calc(100% - 40px)",
            overflowY: "auto",
            "&::-webkit-scrollbar": { width: "4px", height: "100%" },
            "&::-webkit-scrollbar-thumb": { backgroundColor: "#888" },
            "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#555" },
          }}
        >
          {/* site type buttons */}
          <SiteTypeBox>
            {/* first button start*/}
            <AppTypeButton variant="contained" size="small" color="primary">
              <ShoppingCartIcon />
              <Typography sx={{ fontSize: "9px", paddingTop: "5px" }}>
                Grocery
              </Typography>
            </AppTypeButton>
            {/* 2nd button start */}
            <AppTypeButtonOutlined variant="outlined" size="small">
              <MedicationIcon />
              <Typography sx={{ fontSize: "9px", paddingTop: "5px" }}>
                Phermecy
              </Typography>
            </AppTypeButtonOutlined>
            {/* 3rd button start */}
            {/* <AppTypeButtonOutlined
              variant="outlined"
              size="small"
            >
              <FastfoodIcon />
              <Typography sx={{ fontSize: '9px', paddingTop: '5px' }}>
                Cookups
              </Typography>

            </AppTypeButtonOutlined> */}
          </SiteTypeBox>

          <Divider />

          {/* offers */}
          <Box sx={{ paddingX: "15px" }}>
            <List>
              {offersItems.map((item, index) => (
                <ListItem
                  key={index}
                  component={Link}
                  to={item.path}
                  sx={{
                    paddingY: "0px",
                    color: "black",
                    "&:hover": {
                      background:
                        "linear-gradient(to right, #fff 0, #ccc 100%)",
                    },
                  }}
                >
                  {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
                  <ListItemText
                    primary={item.name}
                    sx={{
                      fontSize: "13px",
                      flex: "inherit",
                      paddingRight: "5px",
                    }}
                  />
                  <ListItemIcon>{item.icon}</ListItemIcon>
                </ListItem>
              ))}
            </List>
          </Box>

          <Divider />

          {/* constant categories */}
          <Box sx={{ paddingX: "20px", paddingY: "10px", paddingBottom: { xs: '50px', sm: '10px' } }}>
            <List>
              {drawerItemsone.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    onClick={() => item.subcategory && toggleSubcategory(index)}
                    component={Link}
                    to={item.path}
                    sx={{
                      padding: "0px",
                      color: "black",
                      "&:hover": {
                        background:
                          "linear-gradient(to right, #fff 0, #ccc 100%)",
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      sx={{ fontSize: "13px", paddingLeft: "10px" }}
                    />
                    {item.subcategory &&
                      (openSubcategory[index] ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      ))}
                  </ListItem>
                  {item.subcategory && (
                    <Collapse
                      in={openSubcategory[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {Object.values(item.subcategory).map(
                          (subItem, subIndex) => (
                            <ListItem
                              key={subIndex}
                              component={Link}
                              to={subItem.path}
                              sx={{
                                padding: "0px",
                                paddingLeft: "40px",
                                color: "black",
                                "&:hover": {
                                  background:
                                    "linear-gradient(to right, #fff 0, #ccc 100%)",
                                },
                              }}
                            >
                              <ListItemText primary={subItem.name} />
                            </ListItem>
                          )
                        )}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              ))}
            </List>
          </Box>

          <Divider />

          {/* category menu items */}
          <Box sx={{ paddingX: "20px", paddingY: "10px", paddingBottom: { xs: '50px', sm: '10px' } }}>
            <List>
              {categories.map((category, index) => (
                <React.Fragment key={index}>
                  <ListItem
                    onClick={() => category.subcategories.length > 0 && toggleSubcategory(index)}
                    component={Link}
                    to={`${baseUrl}${generatePath(category.name)}`}
                    sx={{
                      padding: "0px",
                      color: "black",
                      "&:hover": {
                        background: "linear-gradient(to right, #fff 0, #ccc 100%)",
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <CategoryIcon /> {/* Use the Category icon */}
                    </ListItemIcon>
                    <ListItemText
                      primary={category.name}
                      sx={{ fontSize: "10px", paddingLeft: "10px" }}
                    />
                    {category.subcategories.length > 0 &&
                      (openSubcategory[index] ? (
                        <KeyboardArrowDownIcon />
                      ) : (
                        <KeyboardArrowRightIcon />
                      ))}
                  </ListItem>
                  {category.subcategories.length > 0 && (
                    <Collapse
                      in={openSubcategory[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {category.subcategories.map((subcategory, subIndex) => (
                          <ListItem
                            key={subIndex}
                            component={Link}
                            to={`/products/${generatePath(subcategory.name)}`}
                            sx={{
                              padding: "0px",
                              paddingLeft: "40px",
                              color: "black",
                              "&:hover": {
                                background: "linear-gradient(to right, #fff 0, #ccc 100%)",
                              },
                            }}
                          >
                            <ListItemText primary={subcategory.name} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              ))}
            </List>
          </Box>
        </Grid>
        {/* menu items scrollable ends */}

        {/* bottom fixed help and feedback boxs start */}
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <Stack
            direction="row"
            className="drawer-bottom-fixed"
            sx={{
              justifyContent: "space-between",
              backgroundColor: "white",
              borderTop: "1px solid #ccc",
              height: "40px",
              position: "fixed",
              bottom: { xs: '40px', sm: '0' },
              width: "250px",
              boxShadow: "0px -3px 5px rgba(0, 0, 0, 0.2)",
            }}
          >
            <FeedbackButton
              component={Link}
              to="/help"
              sx={{ flexBasis: "35%", color: theme.palette.textcolor.dark }}
            >
              <HelpIcon />
              <Typography fontSize="12px" color="textcolor.dark">
                Help
              </Typography>
            </FeedbackButton>
            <Divider orientation="vertical" flexItem />
            <FeedbackButton sx={{ flexBasis: "65%" }}>
              <ForumIcon />
              <Typography fontSize="12px">File e complainet</Typography>
            </FeedbackButton>
          </Stack>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default Left_SideDrawer;
