import React from 'react'
import { ToggleButton, ToggleButtonGroup, styled } from '@mui/material'

const TogglebuttonnNav = () => {

  // toggle button 
  const [alignment, setAlignment] = React.useState('EN');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const CustomToggleButton = styled(ToggleButton)(({ theme }) => ({
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      backgroundColor: 'white',
      '&:hover':{
        backgroundColor:'white',
      },
    },
  }));

  const CustomToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    backgroundColor: 'white',
  }));

  return (
    <>
      <CustomToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        sx={{height: 30 , display: { xs: 'none', md: 'flex' }}}
      >
        <CustomToggleButton
          value="EN"
        >
          EN
        </CustomToggleButton>

        <CustomToggleButton
          value="বাং"
        >
          বাং
        </CustomToggleButton>
      </CustomToggleButtonGroup>
    </>
  )
}

export default TogglebuttonnNav