import React from 'react'
import { Box, Container, Grid, Typography } from '@mui/material'
import { CategoryWrapper } from './NewProductsSlider'
import BlogsCard from '../BlogsCard'

const OurBlogs = () => {

    const blogItems = [1, 2, 3];

    return (
        <>
            <Container >
                <CategoryWrapper>
                    <Typography>
                        <b>OUR BLOGS</b>
                    </Typography>
                </CategoryWrapper>
                <Box className="bolgs-container" sx={{ paddingY: '25px' }}>
                    <Grid container spacing={4} className="blogs-wrapper">
                        {blogItems.map((item, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4}>
                                <BlogsCard />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default OurBlogs