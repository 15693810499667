import React, { useState } from 'react';
import { Box, Button, Stack, TextField, Typography, CircularProgress } from '@mui/material';
import { handleOtpSubmit } from '../ApiToFatch';

const RegisterForm = ({ handleRegisterSubmit, onRegisterChange, setErrors, errors, navigate, showAlert, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [otpVisible, setOtpVisible] = useState(false);
  const [credentials, setCredentials] = useState({
    username: '',
    emailOrPhone: '',
    password: '',
  });
  const [otp, setOtp] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await handleRegisterSubmit(credentials, navigate, showAlert, handleClose, setErrors);

      if (response.ok) {
        setOtpVisible(true);
        showAlert('success', 'Now Please verify your OTP.');
      }
      // Errors are already set by handleRegisterSubmit, no need to reset here
    } catch (error) {
      setErrors({
        general: 'An unexpected error occurred. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
    onRegisterChange(e);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleOtpConfirmation = async (e) => {
    e.preventDefault();
    setLoading(true);
    await handleOtpSubmit({
      identifier: credentials.emailOrPhone, otp, navigate,
      showAlert,
      handleClose,
      setErrors
    });
    setLoading(false);
  };

  return (
    <Box className="login-with-number" pb={2}>
      <Typography textAlign="center">Register</Typography>
      {!otpVisible ? (
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <Stack paddingY={2} spacing={2}>
            <TextField
              id="standard-username-input"
              name="username"
              label="Username"
              type="text"
              variant="standard"
              placeholder="Enter your username here"
              color="primary"
              focused
              value={credentials.username}
              onChange={handleChange}
              error={Boolean(errors.username)}
              helperText={errors.username}
            />
            <TextField
              id="standard-email-or-phone-input"
              name="emailOrPhone"
              label="Email / Phone Number"
              type="text"
              variant="standard"
              placeholder="Enter your email or phone number here"
              color="primary"
              focused
              value={credentials.emailOrPhone}
              onChange={handleChange}
              error={Boolean(errors.emailOrPhone)}
              helperText={errors.emailOrPhone}
            />
            <TextField
              id="standard-password-input"
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
              variant="standard"
              placeholder="Enter your password"
              color="primary"
              focused
              value={credentials.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password}
            />
          </Stack>

          <Button
            variant="contained"
            type="submit"
            fullWidth
            color="secondary"
            sx={{ position: 'relative' }}
            disabled={loading}
          >
            {loading && (
              <CircularProgress
                size={15}
                sx={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}
              />
            )}
            Signup
          </Button>
        </Box>
      ) : (
        <Box mt={4} component="form" onSubmit={handleOtpConfirmation} noValidate>
          <Typography textAlign="center">Confirm OTP</Typography>
          <Stack spacing={2} mt={2}>
            <TextField
              id="otp-input"
              name="otp"
              label="Enter OTP"
              type="text"
              variant="standard"
              placeholder="Enter the OTP sent to your phone"
              color="primary"
              focused
              value={otp}
              onChange={handleOtpChange}
              error={Boolean(errors.otp)}
              helperText={errors.otp}
            />
            <Button
              variant="contained"
              type="submit"
              fullWidth
              color="secondary"
              sx={{ position: 'relative' }}
              disabled={loading}
            >
              {loading && (
                <CircularProgress
                  size={15}
                  sx={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-50%, -50%)' }}
                />
              )}
              Confirm OTP
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default RegisterForm;
