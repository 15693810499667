import React, { useEffect, useState } from 'react';
import { getAllProductsSellerPublic } from '../../../components/ApiToFatch';
import ProductContainer from '../../../components/product realated/ProductContainer';
import { useFilter } from '../../../auth/FilterContext';
import useFilteredAndSortedProducts from '../../../components/product realated/hooks/useFilteredAndSortedProducts';


const SellerProductsPublic = ({vid}) => {
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 8;

    const { sort, brand, category, filterDispatch , priceRange} = useFilter();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const productsData = await getAllProductsSellerPublic(vid, currentPage, itemsPerPage);
                if (productsData) {
                    setProducts(productsData);
                    setTotalPages(Math.ceil(productsData.count / itemsPerPage));
                } else {
                    // Handle case where data structure is not as expected
                    console.error('Unexpected data structure:', productsData);
                    setProducts([]);
                    setTotalPages(1);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setProducts([]);
                setTotalPages(1);
            }
        };

        fetchData();
    }, [currentPage, vid]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleFiltersChange = (newFilters) => {
        filterDispatch({ type: 'UPDATE_FILTERS', payload: newFilters });
    };

    const sortedProducts = useFilteredAndSortedProducts(products, { sort, brand, category, priceRange });

    return (
        <>
            <ProductContainer products={sortedProducts} totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} onFiltersChange={handleFiltersChange}/>
        </>
    );
};

export default SellerProductsPublic;
