import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { getOrderStatusDetails } from '../ApiToFatch';

const OrderDetails = ({ orderId, orders }) => {
    const [orderStatusDetails, setOrderStatusDetails] = useState(null);

    const order = orders.find(order => order.id === orderId);

    useEffect(() => {
        const fetchOrderStatusDetails = async () => {
            try {
                const response = await getOrderStatusDetails(orderId);
                setOrderStatusDetails(response);
                console.log(orderStatusDetails);
            } catch (error) {
                console.error('Error fetching order status details:', error);
            } 
        };

        fetchOrderStatusDetails();
        // eslint-disable-next-line
    }, [orderId]); // Fetch details whenever orderId changes

    if (!order) {
        return <Typography variant="h6">Order not found</Typography>;
    }

    const subtotal = order.subtotal

    // Calculate total price including VAT and delivery charge
    const totalPrice = parseFloat(order.subtotal) + parseFloat(order.delivery_charge);

    const formattedCreatedAt = new Date(order.created_at).toLocaleString();


    return (
        <Box sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
            <Typography variant="h6" align="center" gutterBottom>
                <b>Order ID:</b> {order.id}
            </Typography>
            <Typography variant="body1" gutterBottom>
                <b>Order Placed Date:</b> {formattedCreatedAt}
            </Typography>
            <Typography variant="body1" gutterBottom>
                <b>Order Status:</b> {order.status_title}
            </Typography>
            {order.status_title === 'Delivered' && orderStatusDetails && (
                <Typography variant="body1" gutterBottom>
                    <b>Order Delivery Date:</b> {new Date(orderStatusDetails.datetime).toLocaleString()}
                </Typography>
            )}
            <Typography variant="body1" gutterBottom>
                <b>Order Notes:</b> {order.order_notes}
            </Typography>
            <Typography variant="body1" gutterBottom>
                <b>Delivery Address:</b>  {order.full_address}
            </Typography>
            <Typography variant="h6" gutterBottom>
                <b>Order Items:</b>
            </Typography>
            {order.items.map(item => (
                <Box key={item.id} sx={{ marginBottom: 2 }}>
                    <Typography variant="body1">
                        <b>{item.product_title}</b>
                    </Typography>
                    <Typography variant="body2">
                        Quantity: {item.quantity}
                    </Typography>
                    <Typography variant="body2">
                        Price/unit: ৳ {item.price}
                    </Typography>
                    <Typography variant="body2">
                        VAT: {item.vat_percentage}%
                    </Typography>
                </Box>
            ))}
            <Typography variant="h6" gutterBottom>
                <b>Subtotal:</b> ৳ {subtotal}
            </Typography>
            <Typography variant="h6" gutterBottom>
                <b>Delivery Charge:</b> ৳ {order.delivery_charge}
            </Typography>
            <Typography variant="h6" gutterBottom>
                <b>Total Price:</b> ৳ {totalPrice.toFixed(2)}
            </Typography>
        </Box>
    );
};

export default OrderDetails;
