import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux_store/store';
import { AuthProvider } from './auth/AuthContext';
import { HelmetProvider } from 'react-helmet-async';
import { FilterProvider } from './auth/FilterContext';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <AuthProvider>
            <FilterProvider>
              <App />
            </FilterProvider>
          </AuthProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);