import React, { useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField,
    Box
} from '@mui/material';

const AddressDialog = ({ open, onClose }) => {
    const [addressData, setAddressData] = useState({
        street: '',
        apartment: '',
        city: '',
        postcode: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddressData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSave = () => {
        // Implement the save functionality
        console.log('Address Data:', addressData);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} disableScrollLock maxWidth='sm' fullWidth >
            <DialogTitle>Add New Address</DialogTitle>
            <DialogContent>
                <Box sx={{display: 'flex' , flexDirection: 'column' , gap: '20px'}}>
                    <Box>
                        <TextField
                            margin="dense"
                            label="Street"
                            name="street"
                            value={addressData.street}
                            onChange={handleChange}
                            fullWidth
                        />

                    </Box>
                    <Box>
                        <TextField
                            margin="dense"
                            label="Apartment"
                            name="apartment"
                            value={addressData.apartment}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Box>

                    <Box>
                        <TextField
                            margin="dense"
                            label="City/Town"
                            name="city"
                            value={addressData.city}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Box>
                    <Box>
                        <TextField
                            margin="dense"
                            label="Postcode/ZIP"
                            name="postcode"
                            value={addressData.postcode}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddressDialog;
