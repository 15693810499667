import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import LiveChat from './utils/LiveChat'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { styled } from '@mui/material/styles';
// import {theme} from '../theme'

export const StartShoppingBox = styled(Box)(({ theme }) => ({
    height: '100%',
    backgroundColor: theme.palette.secondary.main ,
    color: theme.palette.textcolor.main ,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const MobileNavBottom = ({ onDrawerToggle , handleDrawerOpen }) => {
  return (
    <>
        <Grid container sx={{ boxShadow: '0 -1px 10px #ccc' }}> 
            <Grid item xs={3} padding={1} className='mnb-icon'>
                < LiveChat />
            </Grid>
            <Grid item xs={6}>
                <StartShoppingBox className="start-shopping" onClick={onDrawerToggle} >
                    <Typography>START SHOPPING</Typography>
                </StartShoppingBox>
            </Grid>
            <Grid item xs={3} padding={1} className='mnb-icon' sx={{ fontSize: '34px' }}>
                <ShoppingCartCheckoutIcon  onClick={handleDrawerOpen} />
            </Grid>
        </Grid>
    </>
  )
}

export default MobileNavBottom