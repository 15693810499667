import React from 'react'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { theme } from '../../theme';
import GpsFixedTwoToneIcon from '@mui/icons-material/GpsFixedTwoTone';
import LocationCityIcon from '@mui/icons-material/LocationCity';

const Dropdown_Nav = () => {

    const StyledMenu = styled((props) => (
        <Menu
            elevation={0}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            disableScrollLock={true}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            {...props}
        />
    ))(({ theme }) => ({
        '& .MuiPaper-root': {
            borderRadius: 6,
            marginTop: theme.spacing(1),
            minWidth: 180,
            color: theme.palette.textcolor.dark,
            boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
                padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
                padding:'15px',
                '& .MuiSvgIcon-root': {
                    fontSize: 18,
                    color: theme.palette.secondary.main,
                    marginRight: theme.spacing(1.5),
                },
                // '&:active': {
                //     backgroundColor: alpha(
                //         theme.palette.primary.main,
                //         theme.palette.action.selectedOpacity,
                //     ),
                // },
                '&:hover': {
                    backgroundColor: theme.palette.dropdownitemhover.main,
                    color: theme.palette.textcolor.main,
                },

            },
        },
    }));

 
    // const StyledDropdownButton = styled('div')(({ theme }) => ({
    //     color:'secondary',
    //     backgroundColor:'white',
    // }));

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    return (
        <>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                // variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                startIcon={<LocationOnIcon />}
                color='primary'
                sx={{
                    color: theme.palette.secondary.main,
                    fontSize: '16px',
                    height:'100%',
                    display: { xs: 'none', md: 'flex' },
                    
                }}
            >
                Dhaka
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose} disableRipple>
                    <GpsFixedTwoToneIcon />
                    Use my current Location
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={handleClose} disableRipple>
                    <LocationCityIcon />
                    Change City
                </MenuItem>
            </StyledMenu>
        </>
    )
}

export default Dropdown_Nav