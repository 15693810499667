import React, { useState, useEffect } from 'react'
import { Container, Grid, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles';
// import { theme } from '../../theme'
// import FeaturedGridImg from '../../assets/BagLuggage-150x150.jpg'
import { getAllFeaturedCategories } from '../ApiToFatch';
import { Link } from 'react-router-dom';

export const StyledGrid = styled(Grid)(({ theme }) => ({
    padding: '20px',
    backgroundColor: theme.palette.boxcolor.main,
    cursor: 'pointer'
}));

const FeaturedGrid = () => {

    // categories
    const [featuredCategories, setFeaturedCategories] = useState([]);

    // fetch all category with subcategory data 
    useEffect(() => {
        const fetchData = async () => {
            try {

                const categoriesData = await getAllFeaturedCategories();
                setFeaturedCategories(categoriesData);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Slice the array to get only the first six featured categories
    const slicedCategories = featuredCategories.slice(0, 6);

    return (
        <>
            <Container>
                <Box>
                    <Grid container justifyContent={'space-between'}>
                        {slicedCategories.map((item) => (
                            <StyledGrid key={item.id} item xs={12} sm={5.9} md={3.6} className='featured-prodct-box' sx={{ marginBottom: { xs: '10px', md: '40px' } }} >
                                <Link to={`/categories/products/${item.slug}`}  style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Grid container>
                                        <Grid item xs={3} className='featured-img'>
                                            <img src={item.image} alt={item.name} />
                                        </Grid>
                                        <Grid item xs={9} sx={{ padding: '10px 20px' }}>
                                            <Typography><b>{item.name.toUpperCase()}</b></Typography>
                                            <Typography>{item.product_count} Products</Typography>
                                        </Grid>
                                    </Grid>
                                </Link>
                            </StyledGrid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </>
    )
}

export default FeaturedGrid