import { Box, Button, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import bannerWrapperImage from '../../assets/banner.png';
import sellerImageone from '../../assets/seller-image-1.png';
import sellerImagetwo from '../../assets/seller-image-2.png';
import Acordian from '../../components/home page/Acordian';
import Footer from '../../components/Footer/Footer';
import SeoInfo from '../../components/seo/SeoInfo';


const SellerLandingPage = () => {
  return (
    <>
      <SeoInfo
        title='Seller Center'
        description='Become a Seller on Ea-te.com'
        creatorname='Ea-te'
      />
      <Box className="seller-landing-container" sx={{ backgroundColor: '#ffffff' }}>

        <Box className="seller-banner" >
          <Box className="banner-wrapper" sx={{
            textAlign: 'center', padding: '20px 0px', backgroundImage: `url(${bannerWrapperImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            minHeight: '40vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Typography variant='h4' gutterBottom>START YOUR ONLINE BUSINESS WITH US IN 3 EASY STEPS!</Typography>
            <Typography variant="subtitle1" gutterBottom>Become a part of the digital economy <br />
              in Bangladesh through Ea-te</Typography>
            <Link to="/seller-register">
              <Button variant='contained' size='' >
                START SELLING
              </Button>
            </Link>
            <Stack direction="row" spacing={3} sx={{ justifyContent: 'center', marginTop: '20px', gap: '10px', fontSize: '20px' }} className='authhighliter'>
              already a seller?
              <Link to="/seller-login">
                Login
              </Link>
            </Stack>
          </Box>
        </Box>

        <Box sx={{ margin: '30px 0px' }}>
          <Container>
            <Box className="seller-details-images">
              <Box className="seller-details-image">
                <Box className="image-wrapper">
                  <img src={sellerImageone} alt="sellerImageone" />
                </Box>
              </Box>
              <Box className="seller-details-image">
                <Link to="/seller-login">
                  <Box className="image-wrapper">
                    <img src={sellerImagetwo} alt="sellerImagetwo" />
                  </Box>
                </Link>
              </Box>
            </Box>
          </Container>
        </Box>

        <Acordian />
        <Footer />

      </Box >
    </>
  )
}

export default SellerLandingPage