import React from 'react'
import Cities from './Cities'
import { Box } from '@mui/material'
import FooterMain from './Footer_Main'
import CopyRight from './CopyRight'

const Footer = () => {
  return (
    <>
      <Box sx={{backgroundColor: 'rgba(194,194,194,.2)', marginBottom: '40px', marginTop: 'auto'}} className= 'footer'>
        <Cities />
        <FooterMain/>
        <CopyRight/>
      </Box>
    </>
  )
}

export default Footer