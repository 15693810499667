import React, { useState, useContext } from 'react';
import {
  AppBar,
  IconButton,
  Toolbar,
  Stack,
  Box,
  Avatar,
  MenuItem,
  Menu,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/ea-te-logo-transparent-V2-1.png';
import SearchBar from './SearchBar_Nav';
import DropdownNav from './Dropdown_Nav';
import TogglebuttonnNav from './TogglebuttonnNav';
import { Link, useNavigate } from 'react-router-dom';
import { LoginButton } from '../custom buttons/Buttons';
import Alert from '@mui/material/Alert';
import LoginDialog from '../Auth_Forms/LoginDialog';
import AuthContext from '../../auth/AuthContext';
import { handleUserLogout } from '../ApiToFatch';

const adminPanelUrl = process.env.REACT_APP_ADMIN_PANEL_URL;

const Navbar = ({ onDrawerToggle }) => {
  const [open, setOpen] = useState(false);
  const { isLoggedIn, setIsLoggedIn, username, setUsername } = useContext(AuthContext);
  const [alert, setAlert] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();

  // User menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const showAlert = (severity, message) => {
    setAlert({ severity, message });
    setTimeout(() => {
      setAlert(null);
    }, 5000);
  };

  const handleLogout = async () => {
    try {
      await handleUserLogout(setIsLoggedIn, showAlert);
      navigate('/'); // Redirect to home after logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <>
      <AppBar position="fixed" elevation={0} sx={{ backgroundColor: theme.palette.bgcolor.main }}>
        <Toolbar
          sx={{
            [theme.breakpoints.up('sm')]: {
              minHeight: '53px'
            }
          }}
          className='toolbar-custom-layout'
        >
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 0 }}
            onClick={onDrawerToggle}
          >
            <MenuIcon fontSize="inherit" />
          </IconButton>

          <Link to="/">
            <img src={Logo} alt="Logo" style={{ width: '150px', height: '40px' }} />
          </Link>

          <SearchBar />

          <Stack
            sx={{ marginLeft: 'auto', alignItems: 'center' }}
            direction="row"
            spacing={2}
          >
            <DropdownNav />
            <TogglebuttonnNav />

            {!isLoggedIn ? (
              <LoginButton variant="contained" size="small" color="primary" onClick={handleClickOpen}>
                Login
              </LoginButton>
            ) : (
              <>
                <Stack
                  className='user-info-nav'
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  gap='10px'
                  sx={{ cursor: 'pointer' }}
                  onClick={handleMenu}
                >
                  <Avatar
                    alt={username?.username}
                    src="/static/images/avatar/1.jpg"
                    sx={{ width: 24, height: 24 }}
                  />
                  <div className="nav-user-name">
                    <p><b>{username?.username}</b></p>
                  </div>
                </Stack>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  disableScrollLock={true}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {username?.role === 'vendors' ? (
                    <Box>
                      <MenuItem onClick={handleMenuClose}>
                        <Link to='/seller-profile' style={{ textDecoration: 'none' }}>
                          Seller Profile
                        </Link>
                      </MenuItem>
                      <MenuItem onClick={handleMenuClose}>
                        <a
                          href={adminPanelUrl}
                          target='_blank'
                          rel='noopener noreferrer'
                          style={{ textDecoration: 'none', color: 'inherit' }}
                        >
                          Seller Dashboard
                        </a>
                      </MenuItem>
                    </Box>
                  ) : (
                    <MenuItem onClick={handleMenuClose}>
                      <Link to='/profile' style={{ textDecoration: 'none' }}>
                        Dashboard
                      </Link>
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleMenuClose}>
                    <LoginButton variant="contained" size="small" color="primary" onClick={handleLogout}>
                      Logout
                    </LoginButton>
                  </MenuItem>
                </Menu>
              </>
            )}
          </Stack>
        </Toolbar>
      </AppBar>

      <LoginDialog
        open={open}
        handleClose={handleClose}
        navigate={navigate}
        showAlert={showAlert}
        setIsLoggedIn={setIsLoggedIn}
        setUsername={setUsername}
      />

      {alert && (
        <Box
          sx={{
            position: 'fixed',
            top: 60,
            zIndex: 9999,
            textAlign: 'center',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Alert
            severity={alert.severity}
            onClose={() => setAlert(null)}
            sx={{
              position: 'relative',
            }}
          >
            {alert.message}
          </Alert>
        </Box>
      )}
    </>
  );
};

export default Navbar;
