import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from '../auth/AuthContext';
import { Box, Container, Typography, Accordion, AccordionSummary, AccordionDetails, TextField, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Footer from '../components/Footer/Footer';
import SeoInfo from '../components/seo/SeoInfo';

const InformativePage = () => {
    const location = useLocation();
    const { aboutUs, privacyPolicy, termsOfUse, contactUs, faq } = useContext(AuthContext);
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });

    const pathToDataMap = {
        '/about-us': aboutUs[0],
        '/privacy-policy': privacyPolicy[0],
        '/terms-of-use': termsOfUse[0],
        '/contact': contactUs[0],
        '/faq': faq
    };

    const currentData = pathToDataMap[location.pathname];

    // contact form
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you can handle form submission logic (e.g., send data to server, show success message)
        console.log(formData); // Example: Log form data
        // Clear form fields after submission
        setFormData({ name: '', email: '', message: '' });
    };

    return (
        <>
            <SeoInfo
                title={currentData.title}
                description={currentData.subtitle}
                creatorname='Ea-te'
            />
            <Box className="main-container" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh' }}>
                <Container sx={{ paddingTop: '50px' }}>
                    <Box>
                        {location.pathname === '/faq' ? (
                            <>
                                <SeoInfo
                                    title='FAQ'
                                    description='FAQ: Question and Answers are provided here'
                                    creatorname='Ea-te'
                                />
                                <Box>
                                    <Typography variant="h4" gutterBottom>
                                        FAQ
                                    </Typography>
                                    {currentData.map((item) => (
                                        <Accordion key={item.id}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography>{item.question}</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography dangerouslySetInnerHTML={{ __html: item.answer }} />
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </Box>
                            </>
                        ) : (
                            <Box>
                                <Typography variant="h4" gutterBottom>
                                    {currentData.title}
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    {currentData.subtitle}
                                </Typography>
                                <Typography dangerouslySetInnerHTML={{ __html: currentData.description }} />
                            </Box>
                        )}
                        {/* Contact Form */}
                        {location.pathname === '/contact' && (
                            <Box mt={4}>
                                {/* <Typography variant="h4" gutterBottom>
                                    Contact Us
                                </Typography> */}
                                <form onSubmit={handleSubmit}>
                                    <Box>
                                        <TextField
                                            name="name"
                                            label="Name"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            name="email"
                                            label="Email"
                                            type="email"
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            name="message"
                                            label="Message"
                                            multiline
                                            rows={4}
                                            variant="outlined"
                                            fullWidth
                                            margin="normal"
                                            value={formData.message}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    <Button type="submit" variant="contained" color="primary">
                                        Send Message
                                    </Button>
                                </form>
                            </Box>
                        )}
                    </Box>

                </Container>
                <Footer />
            </Box>
        </>
    );
};

export default InformativePage;
