import React from 'react'
import { Link } from 'react-router-dom'
import { Box, Breadcrumbs, Typography } from '@mui/material'

const BreadCrumb = ({ page_title, links }) => {
  return (
    <>
      <Box className="breadcrumb">
        <div className="product-category-title">
          <h1>{page_title}</h1>
        </div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" to="/">
            Home
          </Link>
          {/* dynamic links */}
          {Array.isArray(links) &&
            links.map((link, index) => (
              <Link key={index} underline="hover" color="inherit" to={link.to}>
                {link.label}
              </Link>
            ))}
          <Typography color="primary">{page_title}</Typography>
        </Breadcrumbs>
      </Box>
    </>
  )
}

export default BreadCrumb