import React, { useState, useEffect } from "react";
import LandingBanner from '../components/home page/LandingBanner'
import Footer from '../components/Footer/Footer'
import { Container } from "@mui/material";
import { getProductByNewArivalPage } from "../components/ApiToFatch";
import BannerBackground from '../assets/landingBanner2.png';
import SeoInfo from '../components/seo/SeoInfo';
import ProductContainer from "../components/product realated/ProductContainer";
import { useFilter } from "../auth/FilterContext";
import useFilteredAndSortedProducts from "../components/product realated/hooks/useFilteredAndSortedProducts";


const NewArival = () => {

    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 12;

    const { sort, brand, category, filterDispatch, priceRange } = useFilter();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const productsData = await getProductByNewArivalPage(currentPage, itemsPerPage);
                setProducts(productsData.results);
                setTotalPages(Math.ceil(productsData.count / itemsPerPage));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [currentPage]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleFiltersChange = (newFilters) => {
        filterDispatch({ type: 'UPDATE_FILTERS', payload: newFilters });
    };

    const sortedProducts = useFilteredAndSortedProducts(products, { sort, brand, category, priceRange });

    return (
        <>
            <SeoInfo
                title='New Arival Products'
                description='New Available Products added on Ea-te'
                creatorname='Ea-te'
            />
            <LandingBanner
                backgroundImage={BannerBackground}
                title="NEW PRODUCTS AVAILABLE"
                subTitle="Shop Now !"
            />
            <Container className="product-container" sx={{ marginY: "50px" }}>
                <ProductContainer products={sortedProducts} totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} onFiltersChange={handleFiltersChange}/>
            </Container>
            <Footer />
        </>
    )
}

export default NewArival