import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
// import BannerPopup from '../../assets/imageBanner.png';
// import BannerBackground from '../../assets/landingBanner2.png';
// import SearchInput from '../appbar/SearchInput';
import { styled } from '@mui/material/styles';

export const PromoBoxContainer = styled(Box)(({ theme }) => ({
    padding: '20px',
    backgroundColor: theme.palette.bgcolor.main,
    // width: '100%',
    // height: '60%',
}));

export const PromoBox = styled(Box)(({ theme }) => ({
    color: theme.palette.textcolor.main,
    padding: '30px',
    border: '2px dotted #fff',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
}));



const LandingBanner = ({ backgroundImage, bannerPopupImage ,  title, subTitle }) => {
    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    // marginTop: '53px', 
                    // padding: { xs: '40px 40px 0 40px', lg: '0 0 0 40px' },
                }}
            >
                <Container>
                    <Grid container
                        sx={{ height: { xs: '260px', sm: '400px' } }}
                    >
                        <Grid item xs={12} sm={6} lg={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            {/* <Typography variant='h3' color={'black'}>
                            Grocery Delivered at your Doorstep
                        </Typography>
                        <SearchInput sx={{width: '80%'}}/> */}
                            <PromoBoxContainer className="promo-box-container" fullwidth="true" >
                                <PromoBox className="promo-box">
                                    <div className="title">
                                        <Typography>{title}</Typography>
                                    </div>
                                    <div className="sub-title">
                                        <Typography>{subTitle}</Typography>
                                    </div>
                                </PromoBox>
                            </PromoBoxContainer>
                        </Grid>
                        <Grid item xs={12} lg={6} sx={{ display: 'none' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    flex: '1',
                                    height: 'clamp(300px,25vw,500px)',
                                }}
                            >
                                <img src={bannerPopupImage} alt="banner popup"
                                    style={{
                                        paddingLeft: '30px',
                                        position: 'relative',
                                        bottom: '-20px',
                                        height: '100%',
                                        objectFit: 'contain',
                                        width: '100%',
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default LandingBanner