import React, { useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';

const LoginForm = ({ handleLoginSubmit, onLoginChange }) => {
  const [loginCredentials, setLoginCredentials] = useState({
    username: '',
    password: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLoginSubmit(loginCredentials);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginCredentials({ ...loginCredentials, [name]: value });
    onLoginChange(e);
  };

  return (
    <Box className="login-with-email">
      <Box component="form" onSubmit={handleSubmit} paddingY={2}>
        <Stack spacing={2} sx={{paddingBottom: '20px'}}>
          <TextField
            id="login-username-input"
            name="username"
            label="Email / Username"
            type="text"
            variant="standard"
            placeholder="Enter your username / email here"
            color="primary"
            focused
            value={loginCredentials.username}
            onChange={handleChange}
          />
          <TextField
            id="login-password-input"
            name="password"
            label="Password"
            type="password"
            autoComplete="current-password"
            variant="standard"
            placeholder="Enter your password"
            color="primary"
            focused
            value={loginCredentials.password}
            onChange={handleChange}
          />
        </Stack>

        <Button variant="contained" type="submit" fullWidth color="secondary">
          Login
        </Button>
      </Box>

      <Box className="forgotp-policy" textAlign="center" paddingY={2}>
        <Typography component="a" href="/forgot-password">
          Forgot Password?
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginForm;
