import React, { useState } from 'react'
import { Box, Typography, FormControlLabel, Button, Radio, RadioGroup } from '@mui/material';

const FilterRadioGroup = ({ title, options, selectedOption, onOptionChange }) => {

    const handleChange = (event) => {
        console.log('Radio option changed:', event.target.value);
        onOptionChange(event.target.value); // Dispatch the selected category
    };

    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded((prev) => !prev);
    const visibleOptions = expanded ? options : options.slice(0, 4);

    return (
        <Box className={`by-${title.toLowerCase()}`}>
            <Typography>{title}</Typography>
            <RadioGroup value={selectedOption} onChange={handleChange}>
                {visibleOptions.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        value={option}
                        control={<Radio className="custom-radio" />}
                        label={<Typography className="custom-label">{option}</Typography>}
                    />
                ))}
            </RadioGroup>
            {options.length > 5 && (
                <Button onClick={toggleExpanded} variant="text">
                    {expanded ? 'View Less' : 'View More'}
                </Button>
            )}
        </Box>
    );
};

export default FilterRadioGroup