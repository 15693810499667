import React, { useState, useContext } from 'react';
import { Stack, Typography, Rating, Box, Grid, FormControl, InputLabel, OutlinedInput, Checkbox, Button, FormHelperText } from '@mui/material';
import AuthContext from '../../auth/AuthContext';
import LoginDialog from '../Auth_Forms/LoginDialog';
import Alert from '@mui/material/Alert'; // Import Alert component
import { apiUrl } from '../ApiToFatch';

const ReviewForm = ({ ProductId, addReview }) => {
    const { authTokens, isLoggedIn ,setIsLoggedIn,} = useContext(AuthContext);
    const [alert, setAlert] = useState(null);
    const [errors, setErrors] = useState({});
    const [showLoginDialog, setShowLoginDialog] = useState(false);

    const showAlert = (severity, message) => {
        setAlert({ severity, message });
        setTimeout(() => {
            setAlert(null);
        }, 5000);
    };

    const [formData, setFormData] = useState({
        rating: 0,
        review: '',
        name: '',
        email: '',
        saveInfo: false,
        product: ProductId
    });
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleRatingChange = (event, newValue) => {
        setFormData(prevData => ({
            ...prevData,
            rating: newValue,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (formData.rating === 0) {
            newErrors.rating = 'Rating is required';
        }
        if (!formData.review.trim()) {
            newErrors.review = 'Review is required';
        }
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const createReview = async () => {
        try {
            const accessToken = authTokens?.access;

            if (!accessToken) {
                console.error("No access token found!");
                setShowLoginDialog(true);
                return;
            }

            const response = await fetch(`${apiUrl}/reviews/create/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                // eslint-disable-next-line
                const responseBody = await response.json();
                setFormData({
                    rating: 0,
                    review: '',
                    name: '',
                    email: '',
                    saveInfo: false,
                    product: ProductId
                });
                showAlert('success', 'Review created successfully!');

            } else {
                console.error("HTTP error! Status:", response.status);
            }
        } catch (error) {
            console.error("Error creating review:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            if (!isLoggedIn) {
                setShowLoginDialog(true);
            } else {
                await createReview();
                addReview(formData);
            }
        }
    };

    const handleLoginDialogtoClose = () => {
        setShowLoginDialog(false);
    };

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <Typography>Your email address will not be published. Required fields are marked *</Typography>

                    <div className="rating">
                        <Typography>Your rating *</Typography>
                        <Stack spacing={1}>
                            <Rating
                                name="rating"
                                value={formData.rating}
                                precision={0.5}
                                onChange={handleRatingChange}
                            />
                            {errors.rating && <FormHelperText error>{errors.rating}</FormHelperText>}
                        </Stack>
                    </div>

                    <Box className="review-box" sx={{ paddingRight: '58px' }}>
                        <Typography>Your review *</Typography>
                        <textarea
                            name="review"
                            id="review-details"
                            cols="30"
                            rows="10"
                            value={formData.review}
                            onChange={handleInputChange}
                        />
                        {errors.review && <FormHelperText error>{errors.review}</FormHelperText>}
                    </Box>

                    <Grid container className="reviewer-name-email" gap={{ xs: 4, sm: 0 }}>
                        <Grid item xs={12} sm={6} className="reviewer-name" paddingRight={4}>
                            <FormControl fullWidth error={!!errors.name}>
                                <InputLabel htmlFor="reviewer-name">Name *</InputLabel>
                                <OutlinedInput
                                    id="reviewer-name"
                                    name="name"
                                    label="Name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                                {errors.name && <FormHelperText error>{errors.name}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} className="reviewer-email" paddingRight={4}>
                            <FormControl fullWidth>
                                <InputLabel htmlFor="reviewer-email">Email</InputLabel>
                                <OutlinedInput
                                    id="reviewer-email"
                                    name="email"
                                    label="Email*"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>

                    <div className="checkbox">
                        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems={{ xs: 'flex-start', sm: 'center' }}>
                            <Checkbox
                                name="saveInfo"
                                checked={formData.saveInfo}
                                onChange={(e) => setFormData(prevData => ({ ...prevData, saveInfo: e.target.checked }))}
                            />
                            <Typography>
                                Save my name, email, and website in this browser for the next time I comment.
                            </Typography>
                        </Stack>
                    </div>

                    <div className="submit-button">
                        <Button variant='contained' type="submit">Submit</Button>
                    </div>
                </Stack>

                {showLoginDialog && <LoginDialog open={showLoginDialog} handleClose={handleLoginDialogtoClose} showAlert={showAlert} setIsLoggedIn={setIsLoggedIn}/>}
            </form>

            {alert && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 60,
                        zIndex: 9999,
                        textAlign: 'center',
                        width: {xs: '71%' , md: '55%'},
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Alert
                        severity={alert.severity}
                        onClose={() => setAlert(null)}
                        sx={{
                            position: 'relative',
                        }}
                    >
                        {alert.message}
                    </Alert>
                </Box>
            )}
        </>
    );
};

export default ReviewForm;
