import React, { useState } from 'react'
import { Box, Typography, FormGroup, FormControlLabel, Checkbox, Button } from '@mui/material';


const FilterCheckboxGroup = ({ title, options, selectedValues, onValueChange }) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded(prev => !prev);

    const visibleOptions = expanded ? options : options.slice(0, 4);

    const handleChange = (event) => {
        const value = event.target.value; // Checkbox's value
        const checked = event.target.checked; // Checkbox's checked state
        const valueId = parseInt(value, 10); // Convert string value to number
        onValueChange(valueId, checked);
    };

    return (
        <Box className={`by-${title.toLowerCase()}`}>
            <Typography>{title}</Typography>
            <FormGroup>
                {visibleOptions.map((option) => (
                    <FormControlLabel
                        key={option.id}
                        control={
                            <Checkbox
                                className="custom-checkbox"
                                value={option.id}
                                checked={selectedValues.includes(option.id)}
                                onChange={handleChange}
                            />
                        }
                        label={<Typography className="custom-label">{option.name}</Typography>}
                    />
                ))}
            </FormGroup>
            {options.length > 4 && (
                <Button onClick={toggleExpanded} variant="text">
                    {expanded ? 'View Less' : 'View More'}
                </Button>
            )}
        </Box>
    );
};

export default FilterCheckboxGroup