import React, { useState, useContext } from 'react';
import { Box, Button, Container, Stack, TextField, Typography, Grid, Paper, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import registrationBannner from '../../assets/banner.png';
import Acordian from '../../components/home page/Acordian';
import Footer from '../../components/Footer/Footer';
import { handleLoginSubmit } from '../../components/ApiToFatch';
import AuthContext from '../../auth/AuthContext';
import Alert from '@mui/material/Alert';
import SeoInfo from '../../components/seo/SeoInfo';

const SellerLogin = () => {
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const { setAuthTokens, setIsLoggedIn, setUsername } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [loginCredentials, setLoginCredentials] = useState({
        username: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        username: '',
        password: '',
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        await handleLoginSubmit(loginCredentials, navigate, setIsLoggedIn, showAlert, setUsername, setAuthTokens, setErrors);
        setLoading(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginCredentials({ ...loginCredentials, [name]: value });
    };

    const showAlert = (severity, message) => {
        setAlert({ severity, message });
        setTimeout(() => {
            setAlert(null);
        }, 5000);
    };

    return (
        <>
            <SeoInfo
                title='Login as Seller'
                description='Become a Seller on Ea-te.com'
                creatorname='Ea-te'
            />
            <Box className="seller-registration-container">
                <Box
                    className="seller-reg-area"
                    sx={{
                        backgroundImage: `url(${registrationBannner})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: { xs: 'auto', md: '80vh' },
                        marginBottom: '30px',
                    }}
                >
                    <Container sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                        <Grid container spacing={2} className="seller-reg-wrapper" sx={{ padding: { xs: '40px 0px', md: '0px' } }}>
                            <Grid item xs={12} md={6}>
                                <Box
                                    className="seller-reg-text"
                                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}
                                >
                                    <Typography variant='h3'>
                                        Sell on <br /> Bangladesh’s #1 <br /> Marketplace
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ paddingRight: { xs: '0px', md: '12%' } }}>
                                <Paper elevation={3} className="seller-reg-form" sx={{ padding: '20px 30px' }}>
                                    <Typography variant='h5' textAlign="center">
                                        Login to sell Products on Ea-te
                                    </Typography>
                                    <form onSubmit={handleSubmit}>
                                        <Stack paddingY={2} spacing={2} className='seller-auth-input'>
                                            <TextField
                                                id="login-username-input"
                                                name="username"
                                                label="Email / Username"
                                                type="text"
                                                variant="standard"
                                                placeholder="Enter your username / email here"
                                                color="primary"
                                                focused
                                                value={loginCredentials.username}
                                                onChange={handleChange}
                                                error={Boolean(errors.username)}
                                                helperText={errors.username}
                                            />
                                            <TextField
                                                id="login-password-input"
                                                name="password"
                                                label="Password"
                                                type="password"
                                                autoComplete="current-password"
                                                variant="standard"
                                                placeholder="Enter your password"
                                                color="primary"
                                                focused
                                                value={loginCredentials.password}
                                                onChange={handleChange}
                                                error={Boolean(errors.password)}
                                                helperText={errors.password}
                                            />
                                        </Stack>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            fullWidth
                                            color="secondary"
                                            disabled={loading}
                                            sx={{ position: 'relative' }}
                                        >
                                            {loading && (
                                                <CircularProgress
                                                    size={15}
                                                    sx={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-40%, -50%)' }}
                                                />
                                            )}
                                            Login
                                        </Button>
                                        <Stack
                                            direction="row"
                                            spacing={3}
                                            sx={{ justifyContent: 'center', marginTop: '20px', gap: '10px', fontSize: '20px' }}
                                            className='authhighliter'
                                        >
                                            Not a seller?
                                            <Link to="/seller-register">Register</Link>
                                        </Stack>
                                    </form>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Acordian />
                <Footer />
            </Box>

            {alert && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 60,
                        zIndex: 9999,
                        textAlign: 'center',
                        width: { xs: '100%', xl: '85%' },
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Alert
                        severity={alert.severity}
                        onClose={() => setAlert(null)}
                        sx={{ position: 'relative' }}
                    >
                        {alert.message}
                    </Alert>
                </Box>
            )}
        </>
    );
};

export default SellerLogin;
