import React from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {theme} from '../../theme'

const Card_Static = ({img , title , discription}) => {
    return (
        <>
            <Card sx={{ minHeight: 300 , backgroundColor: theme.palette.boxcolor.main, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {discription}
                    </Typography>
                </CardContent>
                <CardMedia
                    sx={{ height: 140 }}
                    image={img}
                    title="green iguana"
                />
            </Card>
        </>
    )
}

export default Card_Static