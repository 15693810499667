import React, { useRef, useEffect, useState } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Link } from 'react-router-dom';
// import SwiperImg from '../../assets/fruitsAndVegs.png';
// import { useTheme } from '@mui/material';
import { theme } from '../../theme';
import {CategoryWrapper} from './NewProductsSlider';
import { CategoryConatiner } from './NewProductsSlider';
import { getAllCategories } from '../ApiToFatch';

export const StyledPaper = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    padding: '20px',
    // maxHeight: '200px',
    // height: '210px',
    minWidth: '100px',
    boxShadow: '0 0 5px rgba(0,0,0,.19)',
    margin: '10px',
    borderRadius: '10px',
    backgroundColor: theme.palette.boxcolor.main,
}));



const CategoryHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
}));




const CategorySlider = () => {

    // const theme = useTheme();
    const swiperElRef = useRef(null);
    const swiperPrevRef = useRef(null);
    const swiperNextRef = useRef(null);
    const swiperInstanceRef = useRef(null);

    // categories
    const [categories, setCategories] = useState([]);

    // fetch all category with subcategory data 
    useEffect(() => {
        const fetchData = async () => {
            try {

                const categoriesData = await getAllCategories();
                setCategories(categoriesData);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const getSlidesPerView = () => {
        const width = window.innerWidth;
        if (width >= 1280) {
            return 5; // Large and larger screens: 4 slides
        } else if (width >= 960) {
            return 3; // Medium screens: 3 slides
        } else if (width >= 600) {
            return 3; // Small screens: 2 slides
        } else {
            return 2; // Extra small screens: 1 slide
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (swiperInstanceRef.current) {
                swiperInstanceRef.current.params.slidesPerView = getSlidesPerView();
                swiperInstanceRef.current.update();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (swiperElRef.current) {
            swiperInstanceRef.current = new Swiper(swiperElRef.current, {
                slidesPerView: getSlidesPerView(),
                spaceBetween: 5,
            });

            swiperPrevRef.current.addEventListener('click', () => {
                swiperInstanceRef.current.slidePrev();
            });

            swiperNextRef.current.addEventListener('click', () => {
                swiperInstanceRef.current.slideNext();
            });
        }

        return () => {
            if (swiperInstanceRef.current) {
                swiperInstanceRef.current.destroy();
            }
        };
    }, []);


    return (
        <CategoryConatiner >
            <CategoryWrapper sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: '20px', sm: '0' } }}>
                <Typography>
                    <b>Popular Categories</b>
                </Typography>
                <CategoryHeader sx={{ width: { xs: '100%', sm: 'auto' }, justifyContent: { xs: 'space-between', sm: 'none' } }}>
                    <Typography component={Link} to="/categories" sx={{ color: theme.palette.primary.main }}>
                        View All
                    </Typography>
                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button variant="contained" sx={{ backgroundColor: theme.palette.bgcolor.main }} ref={swiperPrevRef}><ArrowCircleLeftIcon /></Button>
                        <Button variant="contained" sx={{ backgroundColor: theme.palette.bgcolor.main }} ref={swiperNextRef}><ArrowCircleRightIcon /></Button>
                    </Box>
                </CategoryHeader>
            </CategoryWrapper>

            <Box sx={{ paddingBottom: '50px', paddingTop: '25px', width: { xs: '100%'}, overflowX: 'hidden' }}>
                <div ref={swiperElRef} className="swiper-container">
                    <div className="swiper-wrapper category-swiper-wrapper">
                        {categories.map((category, index) => (
                            <div key={index} className="swiper-slide">
                                 <Link to={`/categories/${category.slug}`} style={{ textDecoration: 'none', color: 'black' }}>
                                 <StyledPaper elevation={3} sx={{ cursor: 'pointer' }} className='styled-paper-for-img'>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <img src={category.image} alt='item img'/>
                                    </Box>
                                    <Typography>
                                        {category.name}
                                    </Typography>
                                </StyledPaper>
                                 </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </Box>
        </CategoryConatiner>
    );
};

export default CategorySlider;
