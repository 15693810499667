import { Card, CardContent } from '@mui/material'
import React from 'react'
import '../../App.css'

const VideoCard = () => {
    return (
        <>
            <Card
                sx={{
                    flexBasis: '50%',
                    '& .css-1sylmom-MuiCardContent-root:last-child': {
                        paddingBottom: 0, 
                    },
                }}
            >
                <CardContent sx={{ width: '100%', height: '100%', padding: '0' }} >
                    <iframe width="100%" title='video' height="100%" src="https://www.youtube.com/embed/hvoD7ehZPcM" frameBorder="0" allowFullScreen></iframe>
                </CardContent>
            </Card>
        </>
    )
}

export default VideoCard