// FilterContext.js
import React, { createContext, useContext, useReducer } from 'react';
import { filterReducer } from '../redux_store/reducers/filterReducer';

const FilterContext = createContext();

const FilterProvider = ({ children }) => {
    const [filterState, filterDispatch] = useReducer(filterReducer, {
        sort: '',
        brand: [],
        category: 'all',
        priceRange: { min: 0, max: Infinity },
    });

    return (
        <FilterContext.Provider value={{ ...filterState, filterDispatch }}>
            {children}
        </FilterContext.Provider>
    );
};

const useFilter = () => useContext(FilterContext);

export { useFilter, FilterProvider };
