import React, { useState } from 'react';
import { Box, Button, Container, Stack, TextField, Typography, Grid, Paper, CircularProgress } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import registrationBanner from '../../assets/banner.png';
import Acordian from '../../components/home page/Acordian';
import Footer from '../../components/Footer/Footer';
import { handleRegisterSubmitSeller, handleOtpSubmitseller } from '../../components/ApiToFatch';
import Alert from '@mui/material/Alert';
import SeoInfo from '../../components/seo/SeoInfo';

const SellerRegistration = () => {
    const navigate = useNavigate();
    const [alert, setAlert] = useState(null);
    const [loading, setLoading] = useState(false);
    const [otpVisible, setOtpVisible] = useState(false);
    const [otp, setOtp] = useState('');

    const [credentials, setCredentials] = useState({
        username: '',
        emailOrPhone: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        username: '',
        emailOrPhone: '',
        password: '',
    });

    const showAlert = (severity, message) => {
        setAlert({ severity, message });
        setTimeout(() => setAlert(null), 5000);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials({ ...credentials, [name]: value });
        setErrors({ ...errors, [name]: '' }); // Clear error when user types
    };

    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await handleRegisterSubmitSeller(credentials, navigate, showAlert, setErrors);
            if (response.ok) {
                setOtpVisible(true);
                showAlert('success', 'Now Please verify your OTP.');
            }
        } catch (error) {
            console.error('Error during registration:', error);
            showAlert('error', 'An unexpected error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleOtpConfirmation = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            await handleOtpSubmitseller({ identifier: credentials.emailOrPhone, otp, navigate, showAlert, setErrors });
        } catch (error) {
            console.error('Error during OTP confirmation:', error);
            showAlert('error', 'An unexpected error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <SeoInfo
                title='Register as Seller'
                description='Become a Seller on Ea-te.com'
                creatorname='Ea-te'
            />
            <Box className="seller-registration-container">
                <Box className="seller-reg-area" sx={{
                    backgroundImage: `url(${registrationBanner})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    height: { xs: 'auto', md: '80vh' },
                    marginBottom: '30px'
                }}>
                    <Container sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                        <Grid container spacing={2} className="seller-reg-wrapper" sx={{ padding: { xs: '40px 0px', md: '0px' } }}>
                            <Grid item xs={12} md={6}>
                                <Box className="seller-reg-text" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <Typography variant='h3'>Sell on <br /> Bangladesh’s #1 <br /> Marketplace</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ paddingRight: { xs: '0px', md: '12%' } }}>
                                <Paper elevation={3} className="seller-reg-form" sx={{ padding: '20px 30px' }}>
                                    <Typography variant='h5' textAlign="center">Register as Seller of Ea-te</Typography>

                                    {!otpVisible ? (
                                        <form onSubmit={handleSubmit}>
                                            <Stack paddingY={2} spacing={2} className='seller-auth-input'>
                                                <TextField
                                                    id="standard-username-input"
                                                    name="username"
                                                    label="Username"
                                                    type="text"
                                                    variant="standard"
                                                    placeholder="Enter your username here"
                                                    color="primary"
                                                    focused
                                                    value={credentials.username}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.username)}
                                                    helperText={errors.username}
                                                />
                                                <TextField
                                                    id="standard-email-input"
                                                    name="emailOrPhone"
                                                    label="Email / Phone"
                                                    type="text"
                                                    variant="standard"
                                                    placeholder="Enter your email / phone here"
                                                    color="primary"
                                                    focused
                                                    value={credentials.emailOrPhone}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.emailOrPhone)}
                                                    helperText={errors.emailOrPhone}
                                                />
                                                <TextField
                                                    id="standard-password-input"
                                                    name="password"
                                                    label="Password"
                                                    type="password"
                                                    autoComplete="current-password"
                                                    variant="standard"
                                                    placeholder="Enter your password"
                                                    color="primary"
                                                    focused
                                                    value={credentials.password}
                                                    onChange={handleChange}
                                                    error={Boolean(errors.password)}
                                                    helperText={errors.password}
                                                />
                                            </Stack>
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                fullWidth
                                                color="secondary"
                                                disabled={loading}
                                                sx={{ position: 'relative' }}
                                            >
                                                {loading && <CircularProgress size={15} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
                                                Signup
                                            </Button>
                                            <Stack direction="row" spacing={3} sx={{ justifyContent: 'center', marginTop: '20px', gap: '10px', fontSize: '20px' }} className='authhighliter'>
                                                already a seller?
                                                <Link to="/seller-login">
                                                    Login
                                                </Link>
                                            </Stack>
                                        </form>
                                    ) : (
                                        <Box mt={4}>
                                            <Typography textAlign="center">Confirm OTP</Typography>
                                            <form onSubmit={handleOtpConfirmation}>
                                                <Stack spacing={2} mt={2}>
                                                    <TextField
                                                        id="otp-input"
                                                        name="otp"
                                                        label="Enter OTP"
                                                        type="text"
                                                        variant="standard"
                                                        placeholder="Enter the OTP sent to your phone"
                                                        color="primary"
                                                        focused
                                                        value={otp}
                                                        onChange={handleOtpChange}
                                                        error={Boolean(errors.otp)}
                                                        helperText={errors.otp}
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                        fullWidth
                                                        color="secondary"
                                                        sx={{ position: 'relative' }}
                                                        disabled={loading}
                                                    >
                                                        {loading && (
                                                            <CircularProgress
                                                                size={15}
                                                                sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                                            />
                                                        )}
                                                        Confirm OTP
                                                    </Button>
                                                </Stack>
                                            </form>
                                        </Box>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>

                <Acordian />
                <Footer />
            </Box>

            {alert && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 60,
                        zIndex: 9999,
                        textAlign: 'center',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Alert
                        severity={alert.severity}
                        onClose={() => setAlert(null)}
                        sx={{ position: 'relative' }}
                    >
                        {alert.message}
                    </Alert>
                </Box>
            )}
        </>
    );
};

export default SellerRegistration;
