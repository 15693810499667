import React, { useState, useContext } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AuthContext from '../../auth/AuthContext';

export const WrapperBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    border: '1.4px solid #e8e8e8',
    borderRadius: '5px',
    '& .MuiAccordionSummary-root.Mui-expanded': {
        color: theme.palette.secondary.main, // Change color to primary when expanded
    },
}));




const Acordian = () => {

    const [expanded, setExpanded] = useState(false);
    const { faq } = useContext(AuthContext);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Container
                sx={{ marginBottom: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <WrapperBox sx={{ width: { xs: '100%', sm: '70%' } }}>

                    <Typography textAlign="center" mb={2}>
                        Common Questions
                    </Typography>

                    {faq.map((item, index) => (
                        <StyledAccordion
                            key={item.id}
                            expanded={expanded === `panel${index + 1}`}
                            onChange={handleChange(`panel${index + 1}`)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box>
                                    <Typography>
                                        <span
                                            dangerouslySetInnerHTML={{ __html: item.answer }}
                                        />
                                    </Typography>
                                </Box>
                            </AccordionDetails>
                        </StyledAccordion>
                    ))}

                </WrapperBox>
            </Container>
        </>
    )
}

export default Acordian