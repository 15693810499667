import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button
} from '@mui/material';

export default function VerificationDialog({ open, onClose, type }) {
    const [step, setStep] = useState(1); // 1: enter contact info (email/phone), 2: enter OTP
    const [contact, setContact] = useState('');
    const [otp, setOtp] = useState('');

    const handleContactSubmit = (event) => {
        event.preventDefault();
        // Assuming you would send the OTP request here and wait for response
        setStep(2); // Move to the OTP input step
    };

    const handleOtpSubmit = (event) => {
        event.preventDefault();
        // Handle OTP confirmation logic here
        console.log(`${type} OTP confirmed:`, otp);
        onClose(); // Close the dialog after confirmation
        setStep(1);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                component: 'form',
                onSubmit: step === 1 ? handleContactSubmit : handleOtpSubmit,
            }}
            disableScrollLock
        >
            <DialogTitle>
                {step === 1 ? `Add ${type === 'email' ? 'Email' : 'Phone Number'}` : 'Confirm OTP'}
            </DialogTitle>
            <DialogContent>
                {step === 1 && (
                    <>
                        <DialogContentText>
                            Enter your {type === 'email' ? 'email address' : 'phone number'} to receive an OTP for confirmation.
                        </DialogContentText>
                        <TextField
                            focused
                            required
                            margin="dense"
                            id={type}
                            name={type}
                            label={type === 'email' ? 'Email Address' : 'Phone Number'}
                            type={type === 'email' ? 'email' : 'tel'}
                            fullWidth
                            variant="standard"
                            value={contact}
                            onChange={(e) => {
                                if (type === 'phone') {
                                    const numericValue = e.target.value.replace(/[^0-9]/g, '');
                                    setContact(numericValue);
                                } else {
                                    setContact(e.target.value);
                                }
                            }}
                            inputProps={type === 'phone' ? { inputMode: 'numeric', pattern: '[0-9]*' } : {}}
                        />
                    </>
                )}
                {step === 2 && (
                    <>
                        <DialogContentText>
                            Enter the OTP sent to your {type === 'email' ? 'email address' : 'phone number'}.
                        </DialogContentText>
                        <TextField
                            required
                            margin="dense"
                            id="otp"
                            name="otp"
                            label="OTP"
                            type="text"
                            fullWidth
                            variant="standard"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="submit">{step === 1 ? 'Submit' : 'Confirm OTP'}</Button>
            </DialogActions>
        </Dialog>
    );
}
