import React, { useState, useEffect } from 'react'
// import { Box, Typography, FormGroup, FormControlLabel, Checkbox, TextField, IconButton, Tooltip } from '@mui/material';
import { Box, Typography, TextField, IconButton, Tooltip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
// import StarIcon from '@mui/icons-material/Star';
import { useMatch } from 'react-router-dom';
import { getAllCategories, getAllBrands } from '../ApiToFatch';
import { useFilter } from '../../auth/FilterContext';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import FilterCheckboxGroup from './FilterCheckboxGroup';
import FilterRadioGroup from './FilterRadioGroup';

const FilterSidebar = ({ onFiltersChange  }) => {

    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [priceRange, setPriceRange] = useState({ min: 0, max: 0 });
    const { filterDispatch, category, brand } = useFilter();

    // Fetch categories and brands using the async function pattern
    useEffect(() => {
        const fetchData = async () => {
            try {
                const categoriesData = await getAllCategories();
                setCategories(categoriesData);

                const brandsData = await getAllBrands();
                setBrands(brandsData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Match specific routes
    const isSubcategoryRoute = useMatch('/products/:subcatName');
    const isProductbyCategoryRoute = useMatch('/categories/products/:slug');

    // Check for paths where the filter should always be shown
    const isPopularRoute = useMatch('/products/popular');
    const isNewArrivalRoute = useMatch('/products/newarival');
    const isFavouritesRoute = useMatch('/products/favourites');

    // Determine if the category filter should be shown
    const showCategoriesFilter = (!isSubcategoryRoute && !isProductbyCategoryRoute) || isPopularRoute || isNewArrivalRoute || isFavouritesRoute;


    // useEffect(() => {
    //     console.log('Brand selected:', brand); // Log the current state whenever it changes
    //     console.log('Category selected:', category); // Log the current state whenever it changes
    // }, [brand]);


    // Handle category change
    const handleCategoryChange = (option) => {
        filterDispatch({ type: 'CATEGORY', payload: option });
        onFiltersChange({ category: option, brand });
    };

    // Handle brand change
    const handleBrandChange = (brandId, checked) => {
        if (checked) {
            filterDispatch({ type: 'BRAND', payload: { option: brandId, check: checked } });
            onFiltersChange({ category, brand: [...brand, brandId] });
        } else {
            filterDispatch({ type: 'BRAND', payload: { option: brandId, check: checked } });
            onFiltersChange({ category, brand: brand.filter(item => item !== brandId) });
        }
    };


    // Reset filters to default values
    const handleResetFilters = () => {
        // Reset filter states
        filterDispatch({ type: 'CLEAR' });
        setPriceRange({ min: 0, max: Infinity });
        onFiltersChange({ category: 'all', brand: [], priceRange: { min: 0, max: Infinity } });
    }


    // Handle price range change
    const handlePriceChange = () => {
        filterDispatch({ type: 'PRICE_RANGE', payload: priceRange });
        onFiltersChange({ priceRange });
    };

    // Handle minimum price input change
    const handleMinPriceChange = (e) => {
        const value = parseFloat(e.target.value) || 0;
        setPriceRange(prevState => ({ ...prevState, min: value }));
    };

    // Handle maximum price input change
    const handleMaxPriceChange = (e) => {
        const value = parseFloat(e.target.value) || Infinity;
        setPriceRange(prevState => ({ ...prevState, max: value }));
    };




    return (
        <>
            <Box className="seller-product-filter" sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='h6'>Filter Options</Typography>
                    <Tooltip title="Reset filters" arrow>
                        <IconButton onClick={handleResetFilters} color="primary">
                            <SyncOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                </Box>

                {showCategoriesFilter && (
                    <FilterRadioGroup
                        title="Categories"
                        options={categories.map(category => category.name)}
                        selectedOption={category}
                        onOptionChange={handleCategoryChange}
                    />
                )}

                <FilterCheckboxGroup
                    title="Brands"
                    options={brands.map(brand => ({ id: brand.id, name: brand.name }))}
                    selectedValues={brand}
                    onValueChange={handleBrandChange}
                />

                <Box className="by-price" sx={{ paddingRight: '25px' }}>
                    <Typography>Price Range</Typography>
                    <Box sx={{ display: 'flex', flexDirection: { xs: 'row', sm: 'column', md: 'row' }, alignItems: 'center', gap: '8px', margin: '10px 0px' }}>
                        <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            placeholder='min'
                            className="price-input"
                            value={priceRange.min}
                            onChange={handleMinPriceChange} 
                            InputProps={{
                                endAdornment: <Typography variant="body2">৳</Typography>
                            }}
                        />
                        <TextField
                            variant="outlined"
                            size="small"
                            type="number"
                            placeholder='max'
                            className="price-input"
                            value={priceRange.max === Infinity ? '' : priceRange.max}
                            onChange={handleMaxPriceChange} 
                            InputProps={{
                                endAdornment: <Typography variant="body2">৳</Typography>
                            }}
                        />
                        <IconButton color="primary" aria-label="filter" component="span" onClick={handlePriceChange}>
                            <FilterListIcon />
                        </IconButton>
                    </Box>
                </Box>
                {/* <Box className="by-ratings">
                    <Typography>Ratings</Typography>
                    <FormGroup>
                        {[1, 2, 3, 4, 5].map((rating) => (
                            <FormControlLabel
                                key={rating}
                                control={<Checkbox className="custom-checkbox" />}
                                label={
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {[...Array(rating)].map((_, index) => (
                                            <StarIcon key={index} color="primary" sx={{ fontSize: '20px' }} />
                                        ))}
                                    </Box>
                                }
                            />
                        ))}
                    </FormGroup>
                </Box> */}
            </Box>
        </>
    )
}


export default FilterSidebar