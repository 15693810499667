import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
// import { theme } from '../../theme';
import { ItemButton } from '../custom buttons/Buttons'


const StartShopping = ({ title, discription, buttonText, backgroundImage }) => {
    return (
        <>
            <Card sx={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', minHeight: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'center', flexBasis: '50%' }}>
                <CardContent sx={{ width: '50%' }}>
                    <Typography gutterBottom variant="h5" component="div">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {discription}
                    </Typography>

                    <ItemButton
                        component={Link}
                        to='/popular'
                        variant='contained'
                        sx={{marginTop: '30px'}}
                    >
                        {buttonText}
                    </ItemButton>
                </CardContent>
            </Card>
        </>
    )
}

export default StartShopping