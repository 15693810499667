import React, { forwardRef, useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

const Search = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#fff',
  padding: '0 12px',
  borderRadius: 3,
}));

const SearchInput = forwardRef(({ onSearch, value }, ref) => {
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    // Update local state when the parent component updates the value prop
    setSearchValue(value || ''); // Ensure to handle case when value is null or undefined
  }, [value]);

  const handleChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    onSearch(value);
  };

  return (
    <Search className='search-box-width' sx={{ display: 'flex', width: '100%' }}>
      <InputBase
        placeholder="Search for products"
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleChange}
        value={searchValue}
        ref={ref}
      />
      <IconButton aria-label="SearchIcon">
        <SearchIcon color='searchicon' />
      </IconButton>
    </Search>
  );
});

export default SearchInput;
