import { Box, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles';
import CityBackground from '../../assets/city-background.png'

export const LocationPaper = styled(Paper)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '25px',
    fontSize: '18px',
    backgroundColor: theme.palette.secondary.main ,
    color: theme.palette.textcolor.dark ,
    textAlign: 'center',
    margin: '1%',
    padding: '0',
    alignItems: 'center',
    minHeight: '50px',
    boxShadow: '1px 1px 4px rgba(0,0,0,.19)',
    width: '150px'
}));

export const CityBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0 40px',
    height: '350px',
}));
export const CityArea = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));




const Delivary_Places = () => {
    return (
        <>
            <CityBox
                sx={{
                    backgroundImage: `url(${CityBackground})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            >
            <CityArea>
                <Typography sx={{ marginBottom: '25px' }}>
                    <b>Currently Delivering in</b>
                </Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ gap: '20px' }} >
                    <LocationPaper>
                        <Typography>
                            Dhaka
                        </Typography>
                    </LocationPaper>
                    <LocationPaper>
                        <Typography>
                            Chattogram
                        </Typography>
                    </LocationPaper>
                    <LocationPaper>
                        <Typography>
                            Jashore
                        </Typography>
                    </LocationPaper>
                </Stack>
            </CityArea>
        </CityBox >
        </>
    )
}

export default Delivary_Places