import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import Footer from "../components/Footer/Footer";
import { Container, Grid } from "@mui/material";
import CategoryImg from "../assets/category.png";
import { Typography } from "@mui/material";
import { StyledPaper } from "../components/home page/CategorySlider";
import { getAllCategories } from "../components/ApiToFatch";
import { generatePath } from "../components/left drawer/Left_SideDrawer";
import SeoInfo from '../components/seo/SeoInfo';


const SubCategory = () => {
    // for breadcrumb
    const links = [
        { to: "/categories", label: "All Categories" },
    ];

    const { categoryName } = useParams(); // Get the categoryName from URL parameters

    const [selectedCategory, setSelectedCategory] = useState(null);

    // Fetch category data from backend when the component mounts
    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                // Fetch all categories data from the backend
                const categoriesData = await getAllCategories();

                // Find the selected category based on the categoryName
                const scategory = await categoriesData.find(
                    (category) => category.slug === categoryName
                );
                setSelectedCategory(scategory);
            } catch (error) {
                console.error('Error fetching category data:', error);
            }
        };

        fetchCategoryData();
    }, [categoryName]);

    // If category data is still loading, display a loading indicator
    if (!selectedCategory) {
        return <div>Loading...</div>;
    }

    // Once you have the selectedCategory, you can access its subcategories
    const selectedSubcategories = selectedCategory.subcategories;

    return (
        <>
            <SeoInfo
                title={selectedCategory.name}
                description='Get Product by category on Ea-te'
                creatorname='Ea-te'
            />
            <div className="sub-categories-container layout-container-for-smalldata">
                <BreadCrumb page_title={selectedCategory.name} links={links} />

                <Container>
                    <Grid container sx={{ marginY: "40px", paddingTop: '40px' }} spacing={{ xs: '2', lg: '5' }} className='categories-container'>
                        {/* Render subcategories */}
                        {selectedSubcategories.map((subcategory, index) => (
                            <Grid
                                item
                                key={index}
                                xs={6}
                                sm={4}
                                md={3}
                                className="category-items"
                            >
                                <Link to={`/products/${generatePath(subcategory.name)}`}>
                                    <StyledPaper elevation={3} component="div" className='styled-paper-for-img'>
                                        <div style={{ textAlign: 'center' }}>
                                            {subcategory.image ? (
                                                <img src={subcategory.image} alt="category" />
                                            ) : (
                                                <img src={CategoryImg} alt="category" />
                                            )}
                                        </div>
                                        <Typography>{subcategory.name}</Typography>
                                    </StyledPaper>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Container>

                <Footer />
            </div>
        </>
    );
};

export default SubCategory;
