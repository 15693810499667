import React from 'react'
import LandingBanner from '../components/home page/LandingBanner'
import InfoCard from '../components/home page/InfoCard'
import CategorySlider from '../components/home page/CategorySlider'
import ShopAndGetMore from '../components/home page/ShopAndGetMore'
import AppDownload from '../components/home page/AppDownload'
import DelivaryPlaces from '../components/home page/Delivary_Places'
import Acordian from '../components/home page/Acordian'
import Footer from '../components/Footer/Footer'
import NewProductsSlider from '../components/home page/NewProductsSlider'
import OurBlogs from '../components/home page/OurBlogs'
import { Stack } from '@mui/material'
import FeatureProducts from '../components/home page/FeatureProducts'
import FeaturedGrid from '../components/home page/FeaturedGrid'
import BannerBackground from '../assets/landingBanner2.png';
import SeoInfo from '../components/seo/SeoInfo';

const Home = () => {
  return (
    <>
      <SeoInfo
        title='Ea-te'
        description='Ea-te is an Ecommerce app based on bd.'
        creatorname='Ea-te'
        type='Ecommerce App'
      />
      <Stack className="home" direction="column" sx={{ gap: { xs: '20px', md: '30px' } }}>
        <FeatureProducts />
        <FeaturedGrid />
        <LandingBanner
          backgroundImage={BannerBackground}
          title="SEASONAL SALE STARTED"
          subTitle="GET 20% OFF"
        />
        <InfoCard />
        <CategorySlider />
        <ShopAndGetMore />
        <NewProductsSlider />
        <AppDownload />
        {/* <ChaldalInfo /> */}
        <OurBlogs />
        <DelivaryPlaces />
        <Acordian />
        <Footer />
      </Stack>
    </>
  )
}

export default Home