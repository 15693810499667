import React from 'react'
import { Container, Stack } from '@mui/material';
import ShopCardimg1 from '../../assets/premiumImage.jpg'
import ShopCardimg2 from '../../assets/dailyDealsShopInfo.jpg'
import startShopBgImg from '../../assets/dailyGrocery.png'
import CardStatic from './Card_Static';
import StartShopping from './StartShopping';
import VideoCard from './VideoCard';

const ShopAndGetMore = () => {

    const title1 = "Premium Care";
    const title2 = "Amazing Daily Deals"; 
    const title3 = "Shop & Earn Point"; 

    const discription1 = "Too busy to place an order or handling order issues no need to worry as we give you option to take premium assistance.";
    const discription2 = "Stock up on your favorite groceries for less with our unbeatable daily deals! Don't miss out - limited stock";
    const discription3 = "The more you shop the more you earn - cash back, free shipping, exclusive offers and more. Discover the perks of Egg Club membership.";

    const startShoppingTitle = "Shop your daily necessities";
    const startShoppingDiscription = "Shop from our popular category, Explore special offers and receive grocery on your doorsteps within 1 hour.";
    const startShoppingButtonText = "Start Shopping";


    return (
        <>
            <Container sx={{ paddingY: '40px' }}>
                <Stack direction={{xs: 'column', sm: 'row'}}  spacing={3} sx={{justifyContent: 'space-between'}}>
                    <CardStatic img={ShopCardimg1} title={title1} discription={discription1} />
                    <CardStatic img={ShopCardimg2} title={title2} discription={discription2} />
                    <CardStatic img={ShopCardimg1} title={title3} discription={discription3} />
                </Stack>

                <Stack direction={{xs: 'column', md: 'row'}} spacing={3} sx={{justifyContent: 'space-between', marginTop: '50px'}} >

                    <StartShopping  title={startShoppingTitle} discription={startShoppingDiscription} buttonText={startShoppingButtonText} backgroundImage= {startShopBgImg} />

                    <VideoCard />
                </Stack>
            </Container>
        </>
    )
}

export default ShopAndGetMore