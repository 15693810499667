import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import Footer from "../components/Footer/Footer";
import { getAllCategories } from "../components/ApiToFatch";
import { apiUrl } from "../components/ApiToFatch";
import SeoInfo from '../components/seo/SeoInfo';
import ProductContainer from "../components/product realated/ProductContainer";
import { useFilter } from "../auth/FilterContext";
import useFilteredAndSortedProducts from "../components/product realated/hooks/useFilteredAndSortedProducts";

const ProductsByCategory = () => {

  const { slug } = useParams(); // Get the slug from URL parameters
  const [categoryy, setCategoryy] = useState(null);
  const [products, setProducts] = useState([]);
  const { sort, brand, category, filterDispatch, priceRange } = useFilter();

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        // Fetch all categories data from the backend
        const categoriesData = await getAllCategories();

        // Find the category based on the slug
        const foundCategory = categoriesData.find(category =>
          category.slug === slug
        );

        if (foundCategory) {
          setCategoryy(foundCategory);
        } else {
          console.error('Category not found');
        }
      } catch (error) {
        console.error('Error fetching category data:', error);
      }
    };

    fetchCategoryData();
  }, [slug]);


  useEffect(() => {
    if (categoryy) {
      const getProductByCategory = async () => {
        try {
          const response = await fetch(`${apiUrl}/products/category/${categoryy.id}/`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const responseBody = await response.json();
            setProducts(responseBody);
          } else {
            console.error("HTTP error! Status:", response.status);
            return []; // Return an empty array on error
          }
        } catch (error) {
          console.error("Error fetching products:", error);
          return []; // Return an empty array on error
        }
      };

      getProductByCategory();
    }
  }, [categoryy]); // The effect only runs when `categoryy` changes


  // for breadcrumb
  const links = [
    { to: "/categories", label: "All Categories" }
  ];

  const handleFiltersChange = (newFilters) => {
    filterDispatch({ type: 'UPDATE_FILTERS', payload: newFilters });
  };

  const sortedProducts = useFilteredAndSortedProducts(products, { sort, brand, category, priceRange });

  return (
    <>
      <SeoInfo
        title={category && category.name}
        description='Get Product by category on Ea-te'
        creatorname='Ea-te'
      />
      <Box className="product-page-container">
        {/* breadcrumb  */}
        <BreadCrumb page_title={category && category.name} links={links} />

        {/* product container start here */}
        <Container className="product-container" sx={{ marginY: "50px" }}>
          <ProductContainer products={sortedProducts} onFiltersChange={handleFiltersChange} />
        </Container>

        {/* footer */}
        <Footer />
      </Box>
    </>
  );
};

export default ProductsByCategory;
