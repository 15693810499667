import React from 'react'
import { Box, Typography, Container, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import WalletIcon from '@mui/icons-material/Wallet';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { theme } from '../../theme'
import '../../App.css';

export const MainTile = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
}));

export const CategoryBox = styled(Paper)(({ theme }) => ({
    maxWidth: '100%',
    width: '40%',
    minHeight: {xs:'70px', md:'50px'},
    border: '2px solid rgba(0, 0, 0, .05)',
    margin: '1%',
    padding: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    borderRadius: '10px',
    textAlign: 'center'
}));



// export const StyledPaper = styled(Paper)(({ theme }) => ({
//     padding: '10px', 
//     minHeight: '30px', 
//     display: 'flex', 
//     justifyContent: 'center', 
//     alignItems: 'center', 
//     border: ' 2px solid rgba(0,0,0,.05)',
//     gap:'30px'
// }));


const InfoCard = () => {
    return (
        <>
            <Container>
                <Box sx={{ flexGrow: 1, marginTop: '60px', marginBottom: '60px'  }}>
                    <MainTile>
                        <CategoryBox>
                            <div className="categoryicon">
                                <LocalMallIcon />
                            </div>
                            <Typography>
                                <b style={{ color: theme.palette.secondary.main }} > +15000 products </b>to shop from
                            </Typography>
                        </CategoryBox>

                        <CategoryBox>
                            <div className="categoryicon">
                                <WalletIcon />
                            </div>
                            <Typography>
                                Pay <b style={{ color: theme.palette.secondary.main }}>after</b>  receiving products
                            </Typography>
                        </CategoryBox>

                        <CategoryBox>
                            <div className="categoryicon">
                                <DeliveryDiningIcon />
                            </div>
                            <Typography>
                                Get your delivery within <b style={{ color: theme.palette.secondary.main }}>1 hour</b>
                            </Typography>
                        </CategoryBox>

                        <CategoryBox>
                            <div className="categoryicon">
                                <LocalAtmIcon />
                            </div>
                            <Typography>
                                Get offers that <b style={{ color: theme.palette.secondary.main }}>Save Money</b>
                            </Typography>
                        </CategoryBox>

                    </MainTile>
                </Box>
            </Container>
        </>
    )
}

export default InfoCard