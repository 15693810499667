import React  from 'react'
import { Box, Divider, Grid, Typography } from '@mui/material'


const ProfileInfo = ({storeinfo}) => {

    return (
        <>
            <Box className="profile-info-wrapper" sx={{backgroundColor: 'white'}}>
                <Grid container sx={{padding: '20px'}}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box className="profile-info-inner-texts">
                            <Typography variant='h6'>Joined</Typography>
                            <Typography >New Seller</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box className="profile-info-inner-texts info-with-left-border">
                            <Typography variant='h6'>Shipped on Time</Typography>
                            <Typography sx={{color: 'green'}}>100%</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box className="profile-info-inner-texts info-with-left-border">
                            <Typography variant='h6'>Address</Typography>
                            <Typography >{storeinfo.address}</Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Divider/>
                <Grid container sx={{padding: '20px'}}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box className="profile-info-inner-texts">
                            <Typography variant='h6'>Mobile Number</Typography>
                            <Typography >{storeinfo.mobile_number}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box className="profile-info-inner-texts info-with-left-border">
                            <Typography variant='h6'>Email</Typography>
                            <Typography >{storeinfo.email}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Box className="profile-info-inner-texts info-with-left-border">
                            <Typography variant='h6'>Product Type</Typography>
                            <Typography >{storeinfo.product_type_name}</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default ProfileInfo
