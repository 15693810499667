export const filterReducer = (filterState, { type, payload }) => {
    switch (type) {
        case "UPDATE_SORT":
            return {
                ...filterState,
                sort: payload
            };

        case "CATEGORY":
            return {
                ...filterState,
                category: payload
            }

        case "CLEAR":
            return {
                ...filterState,
                sort: "",
                brand: [],
                category: "all",
                priceRange: { min: 0, max: Infinity },
            }

        case "BRAND":
            return {
                ...filterState,
                brand: payload.check ? [...filterState.brand, payload.option] : (filterState.brand.length > 0 ? filterState.brand.filter(item => item !== payload.option) : [])
            }

        case 'PRICE_RANGE': 
            return { ...filterState, priceRange: payload };

        default:
            return filterState

    }
}