import React from 'react'
import {  Grid } from '@mui/material';
import FilterSidebar from './FilterSidebar';
import ProductWrapper from './ProductWrapper';

const ProductContainer = ({products , totalPages , currentPage , handlePageChange, onFiltersChange }) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3}>
                    <FilterSidebar onFiltersChange={onFiltersChange} />
                </Grid>
                <Grid item xs={12} sm={9} md={9}>
                    <ProductWrapper products={products} totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
                </Grid>
            </Grid>
        </>
    )
}

export default ProductContainer