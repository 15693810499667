import React, { useState, useContext, useEffect } from 'react';
import BreadCrumb from '../components/BreadCrumb'
import Footer from '../components/Footer/Footer'
import { Box, Container, Grid, Stack, TextField, MenuItem, InputLabel, Select, CircularProgress } from '@mui/material'
// table 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// tabs
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
// checkbox
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
// button
import { ItemButton } from '../components/custom buttons/Buttons';
import { useSelector, useDispatch } from 'react-redux';
import { apiUrl } from '../components/ApiToFatch';
import { useNavigate } from 'react-router-dom';
import { clearCart } from '../redux_store/actions/cartActions';
import AuthContext from '../auth/AuthContext';
import SeoInfo from '../components/seo/SeoInfo';

const DELIVERY_CHARGE = 60;


function ccyFormat(num) {
    return typeof num === 'number' && !isNaN(num) ? `${num.toFixed(2)}` : '0.00';
}

function formatTotal(num) {
    return parseFloat(num.toFixed(2));
}


const Checkout = () => {
    // tabs
    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => setValue(newValue);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedIn, username } = useContext(AuthContext);
    // loading state 
    const [loading, setLoading] = useState(false);
    // state for address
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState('');

    // to check address exist or not 
    const [isExistingAddress, setIsExistingAddress] = useState(false);

    // order information details
    const cartItems = useSelector(state => state.cart.items);

    const invoiceSubtotal = cartItems.reduce((acc, item) => {
        const itemTotal = item.quantity * parseFloat(item.product.saleprice);
        const itemTotalWithVAT = itemTotal + (itemTotal * parseFloat(item.product.vat_percentage) / 100);
        return acc + itemTotalWithVAT;
    }, 0);
    const invoiceTotal = formatTotal(invoiceSubtotal + DELIVERY_CHARGE);

    // form state
    const [formValues, setFormValues] = useState({
        firstname: username ? username.firstname : '',
        lastname: username ? username.lastname : '',
        companyname: '',
        city_town: '',
        street: '',
        apartment: '',
        postcode_zip: '',
        phone: username ? username.phonenumber : '',
        email: '',
        ordernote: ''
    });

    // for related states
    const [formErrors, setFormErrors] = useState({});
    const [isAddressSaved, setIsAddressSaved] = useState(false);



    // fetch all addresses by user 
    useEffect(() => {
        if (isLoggedIn) {
            fetch(`${apiUrl}/address/by-user`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            })
                .then(response => response.json())
                .then(data => setAddresses(data))
                .catch(error => console.error('Error fetching addresses:', error));
        }
    }, [isLoggedIn]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
        setIsExistingAddress(false);
    };

    // change address data as selected data
    const handleAddressChange = (e) => {
        const addressId = e.target.value;
        setSelectedAddress(addressId);

        const selectedAddressData = addresses.find(address => address.id === addressId);
        if (selectedAddressData) {
            setFormValues({
                ...formValues,
                firstname: username ? username.firstname : '',
                lastname: username ? username.lastname : '',
                city_town: selectedAddressData.city_town,
                street: selectedAddressData.street,
                apartment: selectedAddressData.apartment,
                postcode_zip: selectedAddressData.postcode_zip,
                phone: username ? username.phonenumber : ''
            });
            setIsExistingAddress(true);
        }
    };

    useEffect(() => {
        console.log("state of existing", isExistingAddress);
    }, [isExistingAddress]);

    const validateForm = () => {
        const errors = {};
        if (!formValues.firstname) errors.firstname = 'First name is required';
        if (!formValues.lastname) errors.lastname = 'Last name is required';
        if (!formValues.city_town) errors.city_town = 'city/town is required';
        if (!formValues.street) errors.street = 'Street address is required';
        if (!formValues.phone) errors.phone = 'Phone number is required';
        return errors;
    };

    const handleAddressFormSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            setIsAddressSaved(true);
        } else {
            setIsAddressSaved(false);
        }
    };


    const handlePlaceOrder = async () => {
        setLoading(true);
        try {

            let addressId;

            if (isExistingAddress === true) {
                addressId = selectedAddress; // Use the selected address ID if it exists
            } else {
                // Create a new address
                const addressResponse = await fetch(`${apiUrl}/address/create/`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formValues),
                    credentials: 'include',
                });

                if (addressResponse.status !== 201) {
                    console.error('Failed to create address');
                    return;
                }

                const addressData = await addressResponse.json();
                console.log("new address data if created", addressData)
                addressId = addressData.id; // Use the newly created address ID
            }

            // Step 2: Prepare the order payload including order items
            const itemsPayload = cartItems.map(item => ({
                product_title: item.product.title,
                quantity: item.quantity,
                vendor: item.product.vendor,
                vat_percentage: parseFloat(item.product.vat_percentage),
                price: parseFloat(item.product.saleprice),
            }));

            const orderPayload = {
                address: addressId,
                order_notes: formValues.ordernote,
                subtotal: invoiceSubtotal,
                delivery_charge: DELIVERY_CHARGE,
                total: invoiceTotal,
                items: itemsPayload
            };


            // Step 3: Send the order payload to create the order
            const orderResponse = await fetch(`${apiUrl}/order/create/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderPayload),
                credentials: 'include',
            });

            if (orderResponse.status === 201) {
                dispatch(clearCart());
                navigate('/order-success');
            } else {
                console.error('Failed to create order');
            }
        } catch (error) {
            console.error('Error placing order:', error);
        }
        setLoading(false);
    };



    return (
        <>
            <SeoInfo
                title='CheckOut'
                description='Checkout page on Ea-te'
                creatorname='Ea-te'
            />
            <div className="checkout-container">
                <BreadCrumb page_title="CHECKOUT" />
                <Container className="checkout-main">
                    <Grid container spacing={2} justifyContent={'space-between'} sx={{ marginY: '40px' }}>
                        <Grid item xs={12} md={6}>
                            <Box className="checkout-details-form">
                                <h3>BILLING DETAILS</h3>
                                <hr />
                                <form className='address-form' onSubmit={handleAddressFormSubmit}>
                                    <Box className="address-selection">
                                        <InputLabel variant="standard" htmlFor="address-select">
                                            Saved Addresses
                                        </InputLabel>
                                        <Select
                                            id="address-select"
                                            value={selectedAddress}
                                            onChange={handleAddressChange}
                                            displayEmpty
                                            fullWidth
                                            MenuProps={{
                                                disableScrollLock: true,
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>Select here</em>
                                            </MenuItem>
                                            {Array.isArray(addresses) && addresses.length > 0 ? (
                                                addresses.map((address) => (
                                                    <MenuItem key={address.id} value={address.id}>
                                                        {address.city_town}, {address.street}
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem disabled>No addresses available</MenuItem>
                                            )}
                                        </Select>
                                    </Box>
                                    <Stack gap={'20px'} marginTop={'40px'}>
                                        <Box className="customar-name">
                                            <Grid container spacing={4}>
                                                <Grid item xs={6}>
                                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                        First name *
                                                    </InputLabel>
                                                    <TextField
                                                        required
                                                        id="customar-firstname-input"
                                                        name="firstname"
                                                        type="text"
                                                        variant="outlined"
                                                        color="primary"
                                                        value={formValues.firstname}
                                                        onChange={handleInputChange}
                                                        error={!!formErrors.firstname}
                                                        helperText={formErrors.firstname}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                        Last name *
                                                    </InputLabel>
                                                    <TextField
                                                        required
                                                        id="customar-lastname-input"
                                                        name="lastname"
                                                        type="text"
                                                        variant="outlined"
                                                        color="primary"
                                                        value={formValues.lastname}
                                                        onChange={handleInputChange}
                                                        error={!!formErrors.lastname}
                                                        helperText={formErrors.lastname}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box className="companyname">
                                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                Company name (optional)
                                            </InputLabel>
                                            <TextField
                                                id="company-name"
                                                name="companyname"
                                                type="text"
                                                variant="outlined"
                                                color="primary"
                                                value={formValues.companyname}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className="region">
                                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                Town / City *
                                            </InputLabel>
                                            <Select
                                                name="city_town"
                                                value={formValues.city_town}
                                                onChange={handleInputChange}
                                                error={!!formErrors.city_town}
                                                MenuProps={{
                                                    disableScrollLock: true,
                                                }}
                                            >
                                                <MenuItem value="Dinajpur">Dinajpur</MenuItem>
                                                <MenuItem value="Mohammadpur">Mohammadpur</MenuItem>
                                                <MenuItem value="Dhaka">Dhaka</MenuItem>
                                            </Select>
                                            {formErrors.city_town && <p style={{ color: 'red', margin: '0' }}>{formErrors.city_town}</p>}
                                        </Box>
                                        <Box className="streat-address" sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                Street address *
                                            </InputLabel>
                                            <TextField
                                                required
                                                id="streat-address-one"
                                                name="street"
                                                type="text"
                                                variant="outlined"
                                                placeholder='House Address & Streat Name'
                                                color="primary"
                                                value={formValues.street}
                                                onChange={handleInputChange}
                                                error={!!formErrors.street}
                                                helperText={formErrors.street}
                                            />
                                            <TextField
                                                required
                                                id="streat-address-two"
                                                name="apartment"
                                                type="text"
                                                variant="outlined"
                                                placeholder='Apartment , Suite , etc (optional)'
                                                color="primary"
                                                value={formValues.apartment}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className="post-code">
                                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                Postcode / ZIP (optional)
                                            </InputLabel>
                                            <TextField
                                                id="post-code"
                                                name="postcode_zip"
                                                type="text"
                                                variant="outlined"
                                                color="primary"
                                                value={formValues.postcode_zip}
                                                onChange={handleInputChange}
                                            />
                                        </Box>
                                        <Box className="phone">
                                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                Phone *
                                            </InputLabel>
                                            <TextField
                                                required
                                                id="phone"
                                                name="phone"
                                                type="number"
                                                variant="outlined"
                                                color="primary"
                                                value={formValues.phone}
                                                onChange={handleInputChange}
                                                error={!!formErrors.phone}
                                                helperText={formErrors.phone}
                                            />
                                        </Box>
                                        {/* <Box className="email">
                                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                                    Email
                                                </InputLabel>
                                                <TextField
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    variant="outlined"
                                                    color="primary"
                                                    value={formValues.email}
                                                    onChange={handleInputChange}
                                                    error={!!formErrors.email}
                                                    helperText={formErrors.email}
                                                />
                                            </Box> */}
                                        <Box>
                                            <ItemButton fullWidth type="submit" disabled={isAddressSaved === true}>
                                                {isAddressSaved === false ? (
                                                    <>Save & Continue</>
                                                ) : (
                                                    <>Saved</>
                                                )}
                                            </ItemButton>
                                        </Box>
                                    </Stack>
                                </form>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box className="checkout-aditional-info">
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Order notes (optional)
                                </InputLabel>
                                <TextField
                                    multiline
                                    id="order-note"
                                    name="ordernote"
                                    variant="outlined"
                                    color="primary"
                                    placeholder='Add your order notes here.'
                                    fullWidth
                                    minRows={6}
                                    value={formValues.ordernote}
                                    onChange={handleInputChange}
                                    sx={{minHeight: '120px'}}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box className="checkout-order-details">
                        <h3>YOUR ORDER</h3>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="spanning table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" colSpan={3}>
                                            <b>Details</b>
                                        </TableCell>
                                        <TableCell align="center" colSpan={2}><b>Price</b></TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell><b>Description</b></TableCell>
                                        <TableCell align="right"><b>Qty.</b></TableCell>
                                        <TableCell align="right"><b>Vat</b></TableCell>
                                        <TableCell align="right"><b>price/unit</b></TableCell>
                                        <TableCell align="right"><b>Sum</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cartItems.map((item) => (
                                        <TableRow key={item.product.id}>
                                            <TableCell>{item.product.title}</TableCell>
                                            <TableCell align="right">{item.quantity}</TableCell>
                                            <TableCell align="right">{item.product.vat_percentage}%</TableCell>
                                            <TableCell align="right">{ccyFormat(parseFloat(item.product.saleprice))}</TableCell>
                                            <TableCell align="right">
                                                {ccyFormat(
                                                    item.quantity * parseFloat(item.product.saleprice) * (1 + parseFloat(item.product.vat_percentage) / 100)
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    <TableRow>
                                        <TableCell rowSpan={3} />
                                        <TableCell colSpan={3}><b>Subtotal</b></TableCell>
                                        <TableCell align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3}><b>Delivery Charge</b></TableCell>
                                        <TableCell align="right">{ccyFormat(DELIVERY_CHARGE)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3}><b>Total</b></TableCell>
                                        <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Box className="payment-method-order" sx={{ marginTop: "40px" }}>
                            <h3>Payments Methods</h3>
                            <Stack className="payment-method">
                                <Box>
                                    <TabContext value={value}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                                <Tab label="Cash on delivery" value="1" />
                                                <Tab label="Online Payments" value="2" />
                                            </TabList>
                                        </Box>
                                        <TabPanel value="1">
                                            <Stack className="cash-on-delivery">
                                                <FormControlLabel control={<Checkbox defaultChecked />} label="Cash On Delivery" />
                                            </Stack>
                                        </TabPanel>
                                        <TabPanel value="2">
                                            <p>Not Available Right Now! </p>
                                        </TabPanel>
                                    </TabContext>
                                </Box>
                            </Stack>
                            <Box sx={{ paddingTop: '40px' }}>
                                <ItemButton fullWidth disabled={!isAddressSaved || loading || cartItems.length === 0} onClick={handlePlaceOrder} sx={{ position: 'relative' }} >
                                    {loading && <CircularProgress size={15} sx={{ position: 'absolute', top: '40%', left: '50%', transform: 'translate(-40%, -50%)' }} />}
                                    Place Order
                                </ItemButton>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Footer />
            </div>
        </>
    )
}

export default Checkout