import { combineReducers } from 'redux';
import cartReducer from './cartReducer';
import wishlistReducer from './wishlistReducer';
// import other reducers here

const rootReducer = combineReducers({
    cart: cartReducer,
    wishlist: wishlistReducer
    // other reducers
});

export default rootReducer;
