import React from 'react';
import { Box, Grid } from '@mui/material';
import ProductItemBox from './Product_Item_box';
import PaginationP from './PaginationP';
import FilterBy from './FilterBy';

const ProductWrapper = ({ products, totalPages = 0, currentPage = 1, handlePageChange }) => {

    const totalPageCount = Number(totalPages) || 0;
    const currentPageNumber = Number(currentPage) || 1;
    
    return (
        <>
            <Box className="product-wrapper" sx={{ height: '100%', display: 'flex', flexDirection: 'column', paddingLeft: { xs: '0' , md:'0px'} }}>
                {/* Sort by */}
                <Grid container className="filter-by">
                    <Grid item xs={2}>
                        <Box className="filter-div">
                            <FilterBy />
                        </Box>
                    </Grid>
                </Grid>
                <Grid
                    className="all-products"
                    spacing={1}
                    container
                    sx={{ marginTop: "0px" }}
                >
                    {products.length > 0 ? (
                        <Grid
                            className="all-products"
                            spacing={2}
                            container
                            sx={{ marginTop: "0px", marginLeft: "0px" }}
                        >
                            {products.map((product) => (
                                <Grid key={product.id} item xs={6} sm={4} md={3}>
                                    <ProductItemBox product={product} />
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <Box sx={{ margin: "40px" }}>No products available.</Box>
                    )}
                </Grid>

                <PaginationP 
                    count={totalPageCount}
                    page={currentPageNumber}
                    onChange={handlePageChange}
                />
            </Box>
        </>
    )
}

export default ProductWrapper