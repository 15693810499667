import { Box, Container, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { theme } from '../../theme';
import { Link } from 'react-router-dom';
import AuthContext from '../../auth/AuthContext';

const CopyRight = () => {

    const { basicInfo } = useContext(AuthContext);

    const { fb_link, insta_link, twiter_link } = (basicInfo && basicInfo.length > 0) ? basicInfo[0] : {};

    return (
        <>
            <Box
                sx={{
                    backgroundColor: theme.palette.copyrightbgcolor.main,
                    border: '0.5px solid #c2c2c2',
                    padding: '9px 0',
                }}
            >
                <Container>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography>© 2024 Ea-te Limited</Typography>
                        <Stack direction="row" spacing={1} className='socialicon'>
                            {fb_link && (
                                <Link to={fb_link} target="_blank">
                                    <i className="fab fa-facebook"></i>
                                </Link>
                            )}
                            {twiter_link && (
                                <Link to={twiter_link} target="_blank">
                                    <i className="fab fa-twitter"></i>
                                </Link>
                            )}
                            {insta_link && (
                                <Link to={insta_link} target="_blank">
                                    <i className="fab fa-instagram"></i>
                                </Link>
                            )}
                        </Stack>
                    </Stack>
                </Container>
            </Box >
        </>
    )
}

export default CopyRight