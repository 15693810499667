// AuthProvider.js
import React, { createContext, useState, useEffect } from 'react';
import {
  getAllSliderItems,
  getBasicInfo,
  getFaq,
  getAboutus,
  getPrivacyPolicy,
  getTermsOfUse,
  getContactUs,
  getSingleUser,
  getAllProductsForSearch,
  handleUserLogout
} from '../components/ApiToFatch';
import { apiUrl } from '../components/ApiToFatch';


const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // State variables
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState(null);
  const [sliderItems, setSliderItems] = useState([]);
  const [basicInfo, setBasicInfo] = useState([]);
  const [faq, setFaq] = useState([]);
  const [aboutUs, setAboutUs] = useState([]);
  const [privacyPolicy, setPrivacyPolicy] = useState([]);
  const [termsOfUse, setTermsOfUse] = useState([]);
  const [contactUs, setContactUs] = useState([]);
  const [searchProducts, setSearchProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch user data
  const fetchUserData = async () => {
    try {
      const userData = await getSingleUser();

      if (userData && userData.id) {
        setUsername(userData);
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    } catch (error) {
      setIsLoggedIn(false);  // Ensure isLoggedIn is set to false on error
    }
  };

  // Logout user
  const logoutUser = async () => {
    try {
      await handleUserLogout(setIsLoggedIn);
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };


  // Update token
  const updateToken = async () => {
    try {
      const response = await fetch(`${apiUrl}/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include', // If using cookies for tokens
      });

      if (response.status === 200) {
        // Handle token storage or other logic here
      } else {
      }
    } catch (error) {
      logoutUser();
    }
  };


  // Initial data fetch on mount
  useEffect(() => {
    const initialize = async () => {
      await fetchUserData(); // Fetch user data first
      try {
        const [
          sliderData,
          basicInfoData,
          faqData,
          aboutUsData,
          privacyPolicyData,
          termsOfUseData,
          contactUsData,
          searchProductData
        ] = await Promise.all([
          getAllSliderItems(),
          getBasicInfo(),
          getFaq(),
          getAboutus(),
          getPrivacyPolicy(),
          getTermsOfUse(),
          getContactUs(),
          getAllProductsForSearch()
        ]);
        setSliderItems(sliderData);
        setBasicInfo(basicInfoData);
        setFaq(faqData);
        setAboutUs(aboutUsData);
        setPrivacyPolicy(privacyPolicyData);
        setTermsOfUse(termsOfUseData);
        setContactUs(contactUsData);
        setSearchProducts(searchProductData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, []); // Run only once on mount

  // Set up token refresh interval when logged in
  useEffect(() => {
    let interval;
    if (isLoggedIn) {
      interval = setInterval(updateToken, 1000 * 60 * 2); // Refresh token every 50 minutes
    }
    return () => {
      if (interval) clearInterval(interval);
    };
     // eslint-disable-next-line 
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        setIsLoggedIn,
        username,
        setUsername,
        sliderItems,
        setSliderItems,
        basicInfo,
        faq,
        aboutUs,
        privacyPolicy,
        termsOfUse,
        contactUs,
        searchProducts,
        logoutUser, // Provide logoutUser to context
      }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
