import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    UPDATE_CART_ITEM,
    CLEAR_CART
} from '../actions/cartActions';

const initialState = {
    items: JSON.parse(localStorage.getItem('cart')) || []
};

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_TO_CART:
            const newItem = action.payload;
            const existingItemIndex = state.items.findIndex(item => item.product.id === newItem.product.id);

            if (existingItemIndex !== -1) {
                // If the item already exists in the cart, update its quantity
                const updatedItems = state.items.map((item, index) =>
                    index === existingItemIndex ? { ...item, quantity: item.quantity + newItem.quantity } : item
                );
                localStorage.setItem('cart', JSON.stringify(updatedItems));
                return {
                    ...state,
                    items: updatedItems
                };
            } else {
                // If the item does not exist in the cart, add it
                const updatedItems = [...state.items, newItem];
                localStorage.setItem('cart', JSON.stringify(updatedItems));
                return {
                    ...state,
                    items: updatedItems
                };
            }
        case REMOVE_FROM_CART:
            const filteredItems = state.items.filter(x => x.product.id !== action.payload);
            localStorage.setItem('cart', JSON.stringify(filteredItems));
            return {
                ...state,
                items: filteredItems
            };
        case UPDATE_CART_ITEM:
            const updatedItemsQuantity = state.items.map(item =>
                item.product.id === action.payload.productId
                    ? { ...item, quantity: action.payload.quantity }
                    : item
            );
            localStorage.setItem('cart', JSON.stringify(updatedItemsQuantity));
            return {
                ...state,
                items: updatedItemsQuantity
            };
        case CLEAR_CART:
            localStorage.removeItem('cart');
            return {
                ...state,
                items: []
            };
        default:
            return state;
    }
};

export default cartReducer;
