import { styled } from "@mui/material/styles";
import { Box, Button } from "@mui/material";
// import { theme } from '../../theme';

export const AppTypeButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    padding: '6px 5px',
    minWidth: 0,
    cursor: 'pointer',
    textAlign: 'center',
    border: `1px solid ${theme.palette.secondary.main}`, // Use main color from palette
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    borderRadius: '5px',
}));

export const AppTypeButtonOutlined = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: `${theme.palette.searchicon.main}`,
    padding: '6px 5px',
    minWidth: 0,
    cursor: 'pointer',
    textAlign: 'center',
    border: `1px solid ${theme.palette.secondary.main}`, // Use main color from palette
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    borderRadius: '5px',
}));

export const LoginButton = styled(Button)(({ theme }) => ({
    color: 'white',
    fontSize: '16px',
    height: '36px',
    width: '110px',
    boxShadow: 'none'
}));

export const ItemButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.bgcolor.main,
    color: theme.palette.textcolor.main,
    ':hover': {
        backgroundColor: theme.palette.primary.main, // Change to the color you want on hover
        color: theme.palette.textcolor.main, // Change to the text color you want on hover
    },
}));


export const OfferCountButton = styled(Button)(({ theme }) => ({
    padding: '2px 5px',
    margin: '0px',
    marginLeft: '5px',
    width: 'fit-content',
    minWidth: '20px',
    height: 'fir-content',
    minHeight: '20px',
    fontSize: '11px',
}));

export const StockStatusButton = styled(Button)(({ theme }) => ({
    padding: '2px 5px',
    margin: '0px',
    marginLeft: '5px',
    width: 'fit-content',
    height: 'fir-content',
    fontSize: '11px',
    backgroundColor: ` ${theme.palette.secondary.main}`
}));

export const FeedbackButton = styled(Box)(({ theme }) => ({
    display: 'flex',
    paddingY: '20px',
    paddingLeft: '10px',
    gap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
        background: theme.palette.secondary.main,
    },
}));

export const SiteTypeBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '15px',
    paddingLeft: '15px',
    paddingRight: '15px',
}));