import React, { useRef, useEffect, useState } from 'react';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css'; // Import Swiper styles
import { Box, Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { Link } from 'react-router-dom';
// import { useTheme } from '@mui/material';
import { theme } from '../../theme';
import ProductItemBox from '../product realated/Product_Item_box';
import { getProductByArival } from '../ApiToFatch';

export const CategoryConatiner = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: { xs: '60px', sm: '0' }
}));


export const CategoryWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: '5px',
    // borderBottom: '2px solid #eee',
    position: 'relative', // Add position relative to position absolute pseudo element

    // Styles for the ::after pseudo-element
    '&::after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '60px',
        height: '2px',
        backgroundColor: theme.palette.bgcolor.main,
    },
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '2px',
        backgroundColor: '#eee',
    },
}));


const CategoryHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
}));





const NewProductsSlider = () => {

    // const theme = useTheme();
    const swiperElRef = useRef(null);
    const swiperPrevRef = useRef(null);
    const swiperNextRef = useRef(null);
    const swiperInstanceRef = useRef(null);
    const [newProduct, setNewProduct] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const newProductData = await getProductByArival();
                setNewProduct(newProductData);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    const getSlidesPerView = () => {
        const width = window.innerWidth;
        if (width >= 1280) {
            return 4; // Large and larger screens: 4 slides
        } else if (width >= 960) {
            return 3; // Medium screens: 3 slides
        } else if (width >= 600) {
            return 2; // Small screens: 2 slides
        } else {
            return 1; // Extra small screens: 1 slide
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (swiperInstanceRef.current) {
                swiperInstanceRef.current.params.slidesPerView = getSlidesPerView();
                swiperInstanceRef.current.update();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (swiperElRef.current) {
            swiperInstanceRef.current = new Swiper(swiperElRef.current, {
                slidesPerView: getSlidesPerView(),
                spaceBetween: 25,
                autoplay: {
                    delay: 3000, // Autoplay delay in milliseconds
                },
            });

            swiperPrevRef.current.addEventListener('click', () => {
                swiperInstanceRef.current.slidePrev();
            });

            swiperNextRef.current.addEventListener('click', () => {
                swiperInstanceRef.current.slideNext();
            });
        }

        return () => {
            if (swiperInstanceRef.current) {
                swiperInstanceRef.current.destroy();
            }
        };
    }, []);


    return (
        <>
            <CategoryConatiner >
                <CategoryWrapper sx={{ flexDirection: { xs: 'column', sm: 'row' }, gap: { xs: '20px', sm: '0' } }}>
                    <Typography>
                        <b>NEW ARRIVAL</b>
                    </Typography>
                    <CategoryHeader sx={{ width: { xs: '100%', sm: 'auto' }, justifyContent: { xs: 'space-between', sm: 'none' } }}>
                        <Typography component={Link} to="/products/newarival" sx={{ color: theme.palette.primary.main }}>
                            View All
                        </Typography>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button variant="contained" sx={{ backgroundColor: theme.palette.bgcolor.main }} ref={swiperPrevRef}><ArrowCircleLeftIcon /></Button>
                            <Button variant="contained" sx={{ backgroundColor: theme.palette.bgcolor.main }} ref={swiperNextRef}><ArrowCircleRightIcon /></Button>
                        </Box>
                    </CategoryHeader>
                </CategoryWrapper>

                <Box sx={{ paddingBottom: '50px', paddingTop: '25px', width: { xs: '100%' }, overflowX: 'hidden' }}>
                    <div ref={swiperElRef} className="swiper-container">
                        <div className="swiper-wrapper category-swiper-wrapper">
                            {newProduct.map((product) => (
                                <div key={product.id} className="swiper-slide">
                                    <ProductItemBox product={product} />
                                </div>
                            ))}
                        </div>
                    </div>
                </Box>
            </CategoryConatiner>
        </>
    )
}

export default NewProductsSlider