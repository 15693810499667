import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import BreadCrumb from '../components/BreadCrumb'
import Footer from '../components/Footer/Footer'
import { Container, Grid, Box } from '@mui/material'
import CategoryImg from '../assets/category.png';
// import { theme } from '../theme';
import { Typography } from '@mui/material';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { StyledPaper } from '../components/home page/CategorySlider';
import { getAllCategories } from '../components/ApiToFatch';
import SeoInfo from '../components/seo/SeoInfo';

const baseUrl = '/categories/';
const generatePath = (name) => {
    // Replace special characters with dashes and convert to lowercase
    return encodeURIComponent(name.toLowerCase().replace(/[^\w\s-]/g, '').replace(/\s+/g, '-'));
};


const All_Categories = () => {

    const [categories, setCategories] = useState([]);

    // fetch all category with subcategory data 
    useEffect(() => {
        const fetchData = async () => {
            try {

                const categoriesData = await getAllCategories();
                setCategories(categoriesData);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <SeoInfo
                title='All Categories'
                description='Available categories on Ea-te'
                creatorname='Ea-te'
            />
            <div className="all-categories-container component-container layout-container-for-smalldata">

                <BreadCrumb page_title="All Categories" />

                {/* for all categories */}
                <Container>
                    <Grid
                        container
                        sx={{ marginY: '40px', paddingTop: '40px' }}
                        spacing={{ xs: 1, sm: 2, lg: 5 }}
                        className='categories-container'
                    >
                        {categories.length > 0 ? (
                            categories.map((category, index) => (
                                <Grid
                                    item
                                    key={index}
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    className='category-items'
                                >
                                    <Link
                                        to={`${baseUrl}${generatePath(category.name)}`}
                                        style={{ textDecoration: 'none', color: 'black' }}
                                    >
                                        <StyledPaper elevation={3} component="div" className='styled-paper-for-img'>
                                            <div style={{ textAlign: 'center' }}>
                                                {category.image ? (
                                                    <img src={category.image} alt="category" />
                                                ) : (
                                                    <img src={CategoryImg} alt="category" />
                                                )}
                                            </div>
                                            <Typography>
                                                {category.name}
                                            </Typography>
                                        </StyledPaper>
                                    </Link>
                                </Grid>
                            ))
                        ) : (
                            <Box sx={{ margin: "40px" }}>No categories available.</Box>
                        )}
                    </Grid>
                </Container>

                <Footer />
            </div>
        </>
    )
}

export default All_Categories;