import React from 'react'
import Pagination from '@mui/material/Pagination';
import { Box, Stack } from '@mui/material';


const PaginationP = ({ count = 0, page = 1, onChange }) => {

    const pageCount = Number(count) || 0;
    const currentPage = Number(page) || 1;

    return (
        <>
            <Box className="pagination" sx={{marginTop: 'auto', paddingTop: '25px'}}>
                <Stack spacing={2} >
                    <Pagination
                        count={pageCount}
                        page={currentPage}
                        onChange={onChange}
                        variant="outlined"
                        color="primary"
                        shape="rounded"
                    />
                </Stack>
            </Box>
        </>
    )
}

export default PaginationP