import React, { useState, useEffect, useContext } from 'react';
import {
    Box, Container, Typography, Stack, TextField, Button, Avatar, Fab, Tooltip, MenuItem, Select
} from '@mui/material';
import BreadCrumb from '../components/BreadCrumb';
import Footer from '../components/Footer/Footer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import EditIcon from '@mui/icons-material/Edit';
import UserDp from '../assets/user_dp.png';
import { updateUser } from '../components/ApiToFatch'; // Assuming you have an update function in your API calls
import AuthContext from '../auth/AuthContext';
import Alert from '@mui/material/Alert';
import SeoInfo from '../components/seo/SeoInfo';
import OrderList from '../components/order/OrderList';
import WishListItems from '../components/order/WishListItems';
import VerificationDialog from '../components/utils/VerificationDialog';
import AddressDialog from '../components/utils/AddressDialog';

const MyAccount = () => {
    const { username, setUsername } = useContext(AuthContext);
    const [value, setValue] = useState('1');
    const [userData, setUserData] = useState(username || {});
    const [initialData, setInitialData] = useState(username || {});
    const [editMode, setEditMode] = useState(false);
    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    // Separate dialog states for email and phone
    const [openEmailDialog, setOpenEmailDialog] = useState(false);
    const [openPhoneDialog, setOpenPhoneDialog] = useState(false);
    const [openAddressDialog, setOpenAddressDialog] = useState(false);


    useEffect(() => {
        if (username) {
            setUserData(username);
            setInitialData(username);
        }
    }, [username]);

    const showAlert = (severity, message) => {
        setAlert({ severity, message });
        setTimeout(() => {
            setAlert(null);
        }, 5000);
    };

    const handleEditClick = () => {
        setEditMode(true);
    };

    const handleSaveClick = async () => {
        if (validateFields()) {
            try {
                const updatedFields = {};
                // Check each field to see if it has changed, and if so, add it to updatedFields
                updatedFields.username = userData.username;
                updatedFields.email = userData.email;
                if (userData.firstname !== initialData.firstname) updatedFields.firstname = userData.firstname;
                if (userData.lastname !== initialData.lastname) updatedFields.lastname = userData.lastname;
                if (userData.address !== initialData.address) updatedFields.address = userData.address;
                if (userData.gender !== initialData.gender) updatedFields.gender = userData.gender;
                updatedFields.phonenumber = userData.phonenumber;

                await updateUser(userData.id, updatedFields); // Send only the updated fields
                // Update context data and local state
                setUsername(prevData => ({
                    ...prevData,
                    ...updatedFields,
                }));
                setEditMode(false); // Exit edit mode after successful save
                showAlert('success', 'Information updated successfully!');
                setIsChanged(false); // Reset changes state
            } catch (error) {
                console.error('Error saving user data:', error);
                showAlert('error', 'Error updating information');
            }
        }
    };


    const handleClickGoBack = () => {
        setEditMode(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFieldChange = (event) => {
        const { name, value } = event.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setIsChanged(true); // Set changes state to true on field change
    };

    const validateFields = () => {
        const newErrors = {};

        // Optional fields with specific format checks if they are provided
        if (userData.phonenumber && !/^\d{11}$/.test(userData.phonenumber)) {
            newErrors.phonenumber = 'Invalid phone number';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };



    // Handlers for opening and closing dialogs
    const handleOpenEmailDialog = () => {
        setOpenEmailDialog(true);
    };

    const handleOpenPhoneDialog = () => {
        setOpenPhoneDialog(true);
    };

    const handleCloseEmailDialog = () => {
        setOpenEmailDialog(false);
    };

    const handleClosePhoneDialog = () => {
        setOpenPhoneDialog(false);
    };

    const handleOpenAddressDialog = () => {
        setOpenAddressDialog(true);
    };

    const handleCloseAddressDialog = () => {
        setOpenAddressDialog(false);
    };



    return (
        <>
            <SeoInfo
                title='My Account'
                description='User Profile Information Page Ea-te'
                creatorname='Ea-te'
            />
            <Box className="my-account-container" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh' }}>
                <BreadCrumb page_title="My Account" />
                <Container sx={{ marginY: '40px' }}>
                    <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', padding: '40px 20px', flexDirection: { xs: 'column', md: 'row' } }}>
                        <TabContext value={value}>
                            <Tabs
                                orientation="vertical"
                                variant="scrollable"
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider', minWidth: { xs: '100px', md: '300px' } }}
                            >
                                <Tab label="Dashboard" value="1" />
                                <Tab label="My profile" value="2" />
                                <Tab label="Orders" value="3" />
                                <Tab label="WishList" value="4" />
                                <Tab label="Addresses" value="5" />
                            </Tabs>
                            <Box className="info-panel" sx={{ paddingX: { xs: '10px', md: '50px' }, width: '100%' }}>
                                <TabPanel value="1">
                                    <h1>Dashboard</h1>
                                    <p>Welcome to the user dashboard Saikat.</p>
                                    <Stack className="dashboard-sections" direction={'column'} gap={'40px'}>
                                        <Box>
                                            <h3>Recent Orders</h3>
                                            <p>You Have not yet ordered anything.</p>
                                        </Box>
                                        <Box>
                                            <h3>Transaction History</h3>
                                            <p>No history available.</p>
                                        </Box>
                                        <Box>
                                            <h3>Wishlist Items</h3>
                                            <p>No items available in wishlist.</p>
                                        </Box>
                                    </Stack>
                                </TabPanel>
                                <TabPanel value="2">
                                    <Box className="profile-info" sx={{ position: 'relative' }}>
                                        <Box className="profile-info" sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                            <h1>My Profile</h1>
                                            <Avatar
                                                alt="User Avatar"
                                                src={userData.dp ? userData.dp : UserDp } // Use a default image if dp is not available
                                                sx={{ width: 56, height: 56 }}
                                            />

                                            {!editMode && (
                                                <>
                                                    <Box>
                                                        {/* Username Section */}
                                                        <Typography variant="body1">
                                                            <strong>Username:</strong> {userData.username}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        {/* Email Section */}
                                                        {userData.email ? (
                                                            <Typography variant="body1">
                                                                <strong>Email:</strong> {userData.email}
                                                            </Typography>
                                                        ) : (
                                                            <Button variant="outlined" color="primary" onClick={handleOpenEmailDialog}>
                                                                Add Email
                                                            </Button>
                                                        )}
                                                    </Box>

                                                    <Box>
                                                        {/* Phone Number Section */}
                                                        {userData.phonenumber ? (
                                                            <Typography variant="body1">
                                                                <strong>Phone Number:</strong> {userData.phonenumber}
                                                            </Typography>
                                                        ) : (
                                                            <Button variant="outlined" color="primary" onClick={handleOpenPhoneDialog}>
                                                                Add Phone Number
                                                            </Button>
                                                        )}

                                                    </Box>
                                                </>
                                            )}
                                            <Box>
                                                {editMode ? (
                                                    <TextField
                                                        name="firstname"
                                                        label="First Name"
                                                        variant="outlined"
                                                        value={userData.firstname}
                                                        onChange={handleFieldChange}
                                                        error={!!errors.firstname}
                                                        helperText={errors.firstname}
                                                    />
                                                ) : (
                                                    <Typography variant="body1">
                                                        <strong>First Name:</strong> {userData.firstname}
                                                    </Typography>
                                                )}
                                            </Box>
                                            <Box>
                                                {editMode ? (
                                                    <TextField
                                                        name="lastname"
                                                        label="Last Name"
                                                        variant="outlined"
                                                        value={userData.lastname}
                                                        onChange={handleFieldChange}
                                                        error={!!errors.lastname}
                                                        helperText={errors.lastname}
                                                    />
                                                ) : (
                                                    <Typography variant="body1">
                                                        <strong>Last Name:</strong> {userData.lastname}
                                                    </Typography>
                                                )}
                                            </Box>

                                            <Box>
                                                {editMode ? (
                                                    <Select
                                                        name="gender"
                                                        label="Gender"
                                                        variant="outlined"
                                                        value={userData.gender}
                                                        onChange={handleFieldChange}
                                                        error={!!errors.gender}
                                                        displayEmpty
                                                        sx={{ width: 'fit-content' }}
                                                        MenuProps={{
                                                            disableScrollLock: true,
                                                        }}
                                                    >
                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                        <MenuItem value="male">Male</MenuItem>
                                                        <MenuItem value="female">Female</MenuItem>
                                                    </Select>
                                                ) : (
                                                    <Typography variant="body1">
                                                        <strong>Gender:</strong> {userData.gender}
                                                    </Typography>
                                                )}
                                            </Box>
                                            {!editMode && (
                                                <>
                                                    <Box>
                                                        {userData.address ? (
                                                            <Typography variant="body1">
                                                                <strong>Address:</strong> {userData.address}
                                                            </Typography>
                                                        ) : (
                                                            <Button variant="outlined" color="primary" onClick={handleOpenAddressDialog}>
                                                                Add Address
                                                            </Button>
                                                        )}
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                        {editMode ? (
                                            <Box sx={{ marginTop: '20px', display: 'flex', gap: '20px' }}>
                                                <Button variant="contained" color="primary" onClick={handleSaveClick} disabled={!isChanged}>
                                                    Save Changes
                                                </Button>
                                                <Button variant="contained" color="primary" onClick={handleClickGoBack} >
                                                    Go Back
                                                </Button>
                                            </Box>
                                        ) : (
                                            <Tooltip title="Edit Profile">
                                                <Fab
                                                    color="secondary"
                                                    size="small"
                                                    aria-label="edit"
                                                    sx={{ position: 'absolute', top: '0', right: '30px' }}
                                                    onClick={handleEditClick}
                                                >
                                                    <EditIcon />
                                                </Fab>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </TabPanel>
                                <TabPanel value="3">
                                    <h1>Orders</h1>
                                    <OrderList />
                                </TabPanel>
                                <TabPanel value="4">
                                    <h1>Manage WishList Items</h1>
                                    <WishListItems />
                                </TabPanel>
                                <TabPanel value="5">
                                    <h1>Manage Address</h1>
                                </TabPanel>
                            </Box>
                        </TabContext>
                    </Box>
                </Container>
                <Footer />
            </Box>


            {alert && (
                <Box
                    sx={{
                        position: 'fixed',
                        top: 60,
                        left: '0',
                        zIndex: 9999,
                        textAlign: 'center',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Alert
                        severity={alert.severity}
                        onClose={() => setAlert(null)}
                        sx={{
                            position: 'relative',
                        }}
                    >
                        {alert.message}
                    </Alert>
                </Box>
            )}

            {/* Separate Verification Dialog components */}
            <VerificationDialog
                open={openEmailDialog}
                onClose={handleCloseEmailDialog}
                type="email"
            />
            <VerificationDialog
                open={openPhoneDialog}
                onClose={handleClosePhoneDialog}
                type="phone"
            />

            <AddressDialog
                open={openAddressDialog}
                onClose={handleCloseAddressDialog}
            />
        </>
    );
};

export default MyAccount;
