import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useParams } from "react-router-dom";
import BreadCrumb from "../components/BreadCrumb";
import Footer from "../components/Footer/Footer";
import { getAllCategories } from "../components/ApiToFatch";
import { apiUrl } from "../components/ApiToFatch";
import SeoInfo from '../components/seo/SeoInfo';
import ProductContainer from "../components/product realated/ProductContainer";
import { useFilter } from "../auth/FilterContext";
import useFilteredAndSortedProducts from "../components/product realated/hooks/useFilteredAndSortedProducts";

const Product_List_page = () => {

  const { subcatName } = useParams(); // Get the subcatName from URL parameters
  const [categoryy, setCategoryy] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const { sort, brand, category, filterDispatch , priceRange } = useFilter();



  useEffect(() => {
    const fetchSubCategoryData = async () => {
      try {
        // Fetch all categories data from the backend
        const categoriesData = await getAllCategories();

        // Find the category and subcategory based on the subcatName
        const foundCategory = categoriesData.find(category =>
          category.subcategories.some(subcategory => subcategory.slug === subcatName)
        );

        // Find the subcategory within the found category
        const foundSubcategory = foundCategory.subcategories.find(subcategory => subcategory.slug === subcatName);

        setCategoryy(foundCategory);
        setSubCategory(foundSubcategory);

      } catch (error) {
        
      }
    };

    fetchSubCategoryData();
  }, [subcatName]);


  useEffect(() => {
    const getProductBySubcategory = async () => {
      try {
        const response = await fetch(`${apiUrl}/products/subcategory/${subCategory.id}/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const responseBody = await response.json();
          setProducts(responseBody);

        } else {
          return []; // Return an empty array on error
        }
      } catch (error) {
        return []; // Return an empty array on error
      }
    };

    getProductBySubcategory();

  }, [subCategory, subcatName]);


  // for breadcrumb
  const links = [
    { to: "/categories", label: "All Categories" },
    { to: `/categories/${categoryy && categoryy.slug}`, label: categoryy && categoryy.name }
  ];

  const handleFiltersChange = (newFilters) => {
    filterDispatch({ type: 'UPDATE_FILTERS', payload: newFilters });
  };

  const sortedProducts = useFilteredAndSortedProducts(products, { sort, brand, category, priceRange });

  return (
    <>
      <SeoInfo
        title={subCategory && subCategory.name}
        description='Available products on Ea-te'
        creatorname='Ea-te'
      />
      <Box className="product-page-container component-container">
        {/* breadcrumb  */}
        <BreadCrumb page_title={subCategory && subCategory.name} links={links} />

        {/* product container start here */}
        <Container className="product-container" sx={{ marginY: "50px" }}>
          <ProductContainer products={sortedProducts} onFiltersChange={handleFiltersChange} />
        </Container>

        {/* footer */}
        <Footer />
      </Box>
    </>
  );
};

export default Product_List_page;
