import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../../redux_store/actions/cartActions';
import { ItemButton } from './Buttons';
import MinusButton from '@mui/icons-material/RemoveOutlined';
import PlusButton from '@mui/icons-material/AddOutlined';
import { Stack, Typography } from '@mui/material';
import { updateCartItem, removeFromCart } from '../../redux_store/actions/cartActions';
import AuthContext from '../../auth/AuthContext';

const AddToCartButton = ({ product }) => {
    const dispatch = useDispatch();
    const cartItems = useSelector(state => state.cart.items);
    const [isInCart, setIsInCart] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const { username } = useContext(AuthContext);

    useEffect(() => {
        if (product && product.id) {
            const foundItem = cartItems.find(item => item.product.id === product.id);
            if (foundItem) {
                setIsInCart(true);
                setQuantity(foundItem.quantity);
            } else {
                setIsInCart(false);
                setQuantity(0);
            }
        }
    }, [product, cartItems]);

    const handleAddToCart = () => {
        if (product && product.id) {
            const initialQuantity = 1;
            dispatch(addToCart(product, initialQuantity));
            setIsInCart(true);
            setQuantity(initialQuantity);
        }
    };

    const increaseQuantity = () => {
        if (product && product.id) {
            const currentItem = cartItems.find(item => item.product.id === product.id);
            if (currentItem) {
                const newQuantity = currentItem.quantity + 1;
                dispatch(updateCartItem(product.id, newQuantity));
                setQuantity(newQuantity);
            }
        }
    };

    const decreaseQuantity = () => {
        if (product && product.id) {
            const currentItem = cartItems.find(item => item.product.id === product.id);
            if (currentItem) {
                const newQuantity = currentItem.quantity - 1;
                if (newQuantity <= 0) {
                    dispatch(removeFromCart(product.id));
                    setIsInCart(false);
                    setQuantity(0);
                } else {
                    dispatch(updateCartItem(product.id, newQuantity));
                    setQuantity(newQuantity);
                }
            }
        }
    };

    return (
        <div className="add-to-cart-button">
            {isInCart ? (
                <Stack direction="row" className="number-count-button" sx={{ height: '36.5px', justifyContent: 'space-around' }}>
                    <MinusButton onClick={decreaseQuantity} />
                    <div className="number-count-number">
                        <Typography>{quantity} in cart</Typography>
                    </div>
                    <PlusButton onClick={increaseQuantity} />
                </Stack>
            ) : (
                <ItemButton
                    onClick={handleAddToCart}
                    fullWidth
                    disabled={
                        (product && product.stock_status !== true) ||
                        (username && username.role === 'vendors')
                    }
                >
                    Add to Cart
                </ItemButton>

            )}
        </div>
    );
};

export default AddToCartButton;
